<template>  
  <div class="mt-1 text-right">
    <button type="button" class="mb-3 mr-1 btn btn-primary" @click="onNew()">{{$t('신규')}}</button>
  </div>
</template>

<script>

export default {
  components: {},
  props: [],
  watch: {},
  data() {
    return {
    };
  },
  computed: {
    
  },
  created() {
  },
  methods: {
    // 저장 버튼 
    onNew() {
      this.$emit("new");
    }
  },
};
</script>

