<template>
	<div class="d-flex flex-column h-100">
		<div class="font-weight-bold m-t-10" :style="{ height: '55%' }">
			<cost-map-table :ref="'costMapTable'" :isGridBoxRightFlag="isGridBoxRightFlag" @getCostTableList="getCostTableList" />
		</div>
		<div class="font-weight-bold m-t-10" :style="{ height: '45%' }">
			<cost-point-map-table :ref="'costPointMapTable'" :isGridBoxRightFlag="isGridBoxRightFlag" @getCostMapList="getCostMapList" />
		</div>
	</div>
</template>

<script>
	import costPointMapTable from "../../component/gridBox/management/cost/CostPointMapTable.vue";
	import costMapTable from "../../component/gridBox/management/cost/CostMapTable.vue";

	export default {
		props: ["isGridBoxRightFlag"],
		components: {
			costPointMapTable,
			costMapTable,
		},
		watch: {},
		data() {
			return {};
		},
		created() {},
		mounted() {},
		methods: {
			changeEnergySourceCode(energySourceCode) {
				this.$refs.costBasicCodeComboBox.getCostBasicCodeList(energySourceCode);
			},
			changeCostBasicCode(costBasicCode) {
				this.$refs.costDvsnCodeComboBox.getCostDvsnCodeList(costBasicCode);
			},
			submit(data) {
				// TODO: tagInfo의 Color Property 변경

				// 적용 관제점
				this.$refs.costPointMapTable.setData(data.costMapList);
				this.$refs.costPointMapTable.setCostInfo(data.costInfo);

				// 요금표 관리
				this.$refs.costMapTable.setData(data.costTableList);
				this.$refs.costMapTable.setCostInfo(data.costInfo);
			},
			getCostMapList() {
				this.$emit("getCostMapList");
			},
			getCostTableList() {
				this.$emit("getCostTableList");
			},
			submitCostMapList(data) {
				this.$refs.costPointMapTable.setData(data.costMapList);
				this.$refs.costPointMapTable.setCostInfo(data.costInfo);
			},
			submitCostTableListt(data) {
				this.$refs.costMapTable.setData(data.costTableList);
				this.$refs.costMapTable.setCostInfo(data.costInfo);
			},
			getParam(type) {
				if (type == "costTable") {
					return this.$refs.costTable.getParam();
				}
			},
			getCostDvsnCode() {
				// 요금구분코드 가져오기
				let costDvsnCode = this.$emit("getCostDvsnCode");
				return costDvsnCode;
			},
		},
	};
</script>

<style scoped></style>
