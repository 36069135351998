<template>
    <!-- 에너지원 -->
    <div class="font-weight-bold d-flex flex-row" :style="{ fontSize: '15px'}">
      <div class="p-r-5">
        <select class="form-control" placeholder="요금제 선택" ref="costBasicCode" v-model="costBasicCode" @change="onChanegeCostBasicCode">
          <!-- <option value="">선택</option> -->
          <option v-for="(code, index) in costBasicCodeList" :key="index" :value="code.costBasicCode">
            {{ code.costBasicCodeName }}
          </option>
        </select>
      </div>
    </div>
</template>

<script>
import backEndApi from "@api/backEndApi.js";
import AppErrorHandler from "@src/appErrorHandler";			

export default {
  components: {},
  props: [],
  watch: {},
  data() {
    return {
      costBasicCode : null,
      costBasicCodeList : null
    };
  },
  computed: {
     
  },
  created() {
  },
  mounted(){
  },
  updated(){
  },
  destroyed(){
  },
  methods: {
    async getCostBasicCodeList(energySourceCode) {

      // 이전 리비전과 소스 에서 아래와 같이 변경함.
      /*           
        ↓↓↓↓↓
      */
      try {
        const result = await backEndApi.cost.getBasicList(energySourceCode);
        this.costBasicCodeList = result.data;
        this.costBasicCode = result.data[0].costBasicCode;
        this.$emit("change:costBasicCode", result.data[0].costBasicCode);

      } catch (err) {

        new AppErrorHandler(err)
        .printErrorLog()
        .errHandling();
        
        this.costBasicCodeList = null;
        this.costBasicCode = null;
        this.$emit("change:costBasicCode", null);
      }
    },
    getValue() {
      return this.costBasicCode;
    },
    onChanegeCostBasicCode() {
      this.$emit("change:costBasicCode", this.costBasicCode);
    },
    focus() {
      this.$refs.costBasicCode.focus();
    }
  },
};
</script>

<style scoped>

</style>
