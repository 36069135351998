<template>
	<div class="row p-10">
		<div class="font-weight-bold d-flex flex-row" :style="{ fontSize: '15px' }">
			<div class="pt-2 p-l-5">
				<span>{{$t('기준 연도')}}</span>
			</div>
			<div class="p-l-5 p-r-5">
				<date-picker
					placeholder=""
					v-bind:value="new Date()"
					:ref="'baseDate'"
					class="text-left"
					:style="{ width: '60px' }"
					:disabled="true"
					:config="{ format: 'YYYY', viewMode: 'days', locale: 'ko' }"
				>
				</date-picker>
			</div>
		</div>
		<!-- 휴일구분 -->
		<holi-type-code-combo-box :ref="'holiTypeCodeComboBox'" />

		<holiday-info-btn @search="onSearch" @save="onSave" @delete="onRemove" />
	</div>
</template>

<script>
	import "element-ui/lib/theme-chalk/index.css";
	import backEndApi from "../../../api/backEndApi";
	import holiTypeCodeComboBox from "../../component/comboBox/management/HoliTypeCodeComboBox.vue";
	import holidayInfoBtn from "./HolidayInfoBtn.vue";
  import { StatusCodes } from "http-status-codes";
  import * as popupMessages from '@src/consts/popupMessageConsts';

import AppErrorHandler from "@src/appErrorHandler";

	export default {
		props: [],
		components: {
			holiTypeCodeComboBox,
			holidayInfoBtn,
		},
		watch: {},
		data() {
			return {
        popupMessages
      };
		},
		created() {},
		mounted() {
			this.onSearch();
		},
		methods: {
			async onSearch() {
				let param = {
					baseDate: this.$refs.baseDate.value.getFullYear(),
					holiTypeCode: this.$refs.holiTypeCodeComboBox.getValue(),
				};

				// if(param.holiTypeCode == ""){
				//   this.alertNoti("휴일구분을 선택해주세요.");
				//   this.$refs.holiTypeCodeComboBox.focus();
				//   return;
				// // }

				// backEndApi.calendar.getHolidayList(param).then(({ data }) => {
				//   if(data.length > 0){
				//     this.$emit("search:data",data);
				//   }
				// });

				try {
					let result = await backEndApi.calendar.getHolidayList(param);
					this.$emit("search:data", result.data);
				} catch (err) {
					new AppErrorHandler(err)
						.setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger)
						.createApiHandle(StatusCodes.INTERNAL_SERVER_ERROR)
						.createBizHandle(this.ERR_KIND.MISSING_DATA, popupMessages.COMMON_SEARCH_NO_DATA_POPUP_MESSAGE, this.alertNoti)
						.errHandling(true);

            this.$emit("search:data", []);
				}
			},
			onSave() {
				this.$emit("save");
			},
			onRemove() {
				this.$emit("delete");
			},
		},
	};
</script>

<style scoped></style>
