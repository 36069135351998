<template>
  <div class="col-xl-12 xeEnergyCostAnalysis">
    <header-box :title="$t('휴일 관리')"/>
    <hr class="mt-3 mb-3"/>
    <vvo v-slot="v">
      <search-box :ref="'searchBox'"
      @search:data="onSearch"
      @save="onValidate(v, onSave)"
      @delete="onRemove"
    />

  
    <grid-box :ref="'gridBox'"
      @getCostDvsnCode="getCostDvsnCode"
      @reSearch="reSearch"
    />
    </vvo>


    
  </div>
</template>

<script>
import "element-ui/lib/theme-chalk/index.css";
import headerBox from "../../component/headerBox/management/Header.vue";
import searchBox from "./HolidaySearch.vue"
import gridBox from "./HolidaynfoGrid.vue"


export default {
  props: ["type"],
  components: { 
    headerBox,
    searchBox,
    gridBox,
  },
  watch: {
    
  },
  data() {
    return {
    };
  },
  created() {
  },
  mounted() {
    console.log(this.$refs);
  },
  methods: {
    onSearch(data){
      this.$refs.gridBox.submit(data);
    },
    onSave(){
      this.$refs.gridBox.onSave();
    },
    onRemove(){
      this.$refs.gridBox.onRemove();
    },
    reSearch(){
      this.$refs.searchBox.onSearch();
    },
    getParam(type){
      this.$refs.gridBox.getParam(type);
    },
    getCostDvsnCode(){
      let costDvsnCode = this.$refs.searchBox.getCostDvsnCode();
      return costDvsnCode;
    },
  }
};
</script>

<style scoped>

</style>
