<template>
  <div class="mt-2">
    <div class="container d-flex">
      <!-- 요금제 -->
      <cost-basic-code-combo-box :ref="'costBasicCodeComboBox'"
        @change:costBasicCode="changeCostBasicCode"
      />
      <!-- 요금구분 -->
      <cost-dvsn-code-combo-box :ref="'costDvsnCodeComboBox'"/>

      <cost-info-btn-new :ref="'costInfoBtnNew'"
        @save="onSave"
      />
    </div>
  </div>
</template>

<script>
import costBasicCodeComboBox from "../../component/comboBox/management/CostBasicCodeComboBox.vue";
import costDvsnCodeComboBox from "../../component/comboBox/management/CostDvsnCodeComboBox.vue";
import costInfoBtnNew from "./CostInfoBtnNew.vue";

export default {
  props: [],
  components: { 
    costBasicCodeComboBox,
    costDvsnCodeComboBox,
    costInfoBtnNew
  },
  watch: {
    
  },
  data() {
    return {
      energySourceCode: null
    };
  },
  created() {
  },
  mounted() {
    
  },
  methods: {
    getCostBasicCodeList(energySourceCode){
      this.energySourceCode = energySourceCode;
      this.$refs.costBasicCodeComboBox.getCostBasicCodeList(this.energySourceCode);
    },
    changeCostBasicCode(costBasicCode){
      this.$refs.costDvsnCodeComboBox.getCostDvsnCodeList(costBasicCode);
    },
    getCostBasicCode(){
      let costBasicCode = this.$refs.costBasicCodeComboBox.getValue();
      return costBasicCode;
    },
    getCostDvsnCode(){
      let costDvsnCode = this.$refs.costDvsnCodeComboBox.getValue();
      return costDvsnCode;
    },
    onSave(){
      this.$emit("insert");
    },
  }
};
</script>

<style scoped>

</style>
