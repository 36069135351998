<template>  
  <div class="pr-3 text-right">
    <button type="button" v-if="zoneIdx!=1" class="ml-3 btn btn-primary" @click="onSave()">{{$t('저장')}}</button>
    <!-- <button type="button" v-if="zoneIdx!=1" class="ml-2 btn btn-danger" @click="onRemove()">삭제</button> -->
    <!-- <button type="button" v-if="zoneIdx!=1" class="ml-2 btn btn-light" @click="onReset()">초기화</button> -->
    <button type="button" class="ml-2 btn btn-light" @click="onClose()">{{$t('닫기')}}</button>
  </div>
</template>

<script>

export default {
  components: {},
  props: ["zoneIdx"],
  watch: {},
  data() {
    return {
    };
  },
  computed: {
    
  },
  created() {
  },
  methods: {
    // 저장 버튼 
    onSave() {
      this.$emit("update");
    },
    // 삭제 버튼
    // onRemove(){
    //   // 코드 등록내역 초기화
    //   this.$emit("remove");
    // },
    // 초기화 버튼
    // onReset(){
    //   // 선택 된 코드 삭제
    //   this.$emit("reset");
    // },
    // 편집 창 닫기
    onClose(){
      this.$emit("close");
    }
  },
};
</script>
