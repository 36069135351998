<template>
  <div class="font-weight-bold d-flex flex-row" :style="{ fontSize: '15px'}">
    <b-button :ref="'saveBtn'" squared class="fa-3x m-l-10" variant="#ffffff" size="md" @click="onSave()" style="border:2px solid #348fe2; border-radius: 10px!important;" :style="{ padding: '5px', height:'34px', textAlign: 'center', backgroundColor: '#ffffff', alignItems:'center', width:'55px', height: '100%', padding:0, color:'#348fe2'}" >
      <span>{{"생성"}}</span>
    </b-button> 
  </div>
</template>

<script>
import "element-ui/lib/theme-chalk/index.css";

export default {
  props: [],
  components: { 

  },
  watch: {
    
  },
  data() {
    return {
     
    };
  },
  created() {
  },
  mounted() {
    
  },
  methods: {
    onSave(){
      this.$emit("save");
    },
  }
};
</script>

<style scoped>

</style>
