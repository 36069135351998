<template>
	<b-table
		head-variant="light"
		show-empty
		sticky-header="180px"
		borderless
		outlined
		ref="costTable"
		:style="'min-width:100%;'"
		:class="'text-center mt-2 mb-0 rounded'"
		:items="items"
		:fields="fields"
		:empty-text="$t('조회된 결과가 없습니다.')"
		table-variant="light"
	>
		<template #empty="">
			<h4>{{ "" }}</h4>
		</template>

		<template #cell(codeName)="data">
			<div :style="{ backgroundColor: data.item.color, padding: '12px', height: 'calc(1.5em + 24px + 2px)' }">{{ $t(data.item.codeName) }}</div>
		</template>

		<template #cell(Spring)="data">
			<div :style="{ backgroundColor: data.item.color, padding: '5px' }">
				<valid-input
					:vid="`${data.item.codeName}_${data.field.label}`"
					:inputType="'number'"
					:inputValue.sync="data.item.Spring"
					:classList="'text-right'"
					:rules="rules.COST_ORIGIN_LANG"
					:errorMessage="validMessage.COMMON_DECIMAL_NUMBER_LENGTH_VALID_MESSAGE(6)"
				></valid-input>
				<!-- <input type="number" class="form-control text-right" v-model= "data.item.Spring"/>  -->
			</div>
		</template>
		<template #cell(Summer)="data">
			<div :style="{ backgroundColor: data.item.color, padding: '5px' }">
				<valid-input
					:vid="`${data.item.codeName}_${data.field.label}`"
					:inputType="'number'"
					:inputValue.sync="data.item.Summer"
					:classList="'text-right'"
					:rules="rules.COST_ORIGIN_LANG"
					:errorMessage="validMessage.COMMON_DECIMAL_NUMBER_LENGTH_VALID_MESSAGE(6)"
				></valid-input>
				<!-- <input type="number" class="form-control text-right" v-model= "data.item.Summer"/>    -->
			</div>
		</template>

		<template #cell(Winter)="data">
			<div :style="{ backgroundColor: data.item.color, padding: '5px' }">
				<valid-input
					:vid="`${data.item.codeName}_${data.field.label}`"
					:inputType="'number'"
					:inputValue.sync="data.item.Winter"
					:classList="'text-right'"
					:rules="rules.COST_ORIGIN_LANG"
					:errorMessage="validMessage.COMMON_DECIMAL_NUMBER_LENGTH_VALID_MESSAGE(6)"
				></valid-input>
				<!-- <input type="number" class="form-control text-right" v-model= "data.item.Winter"/>  -->
			</div>
		</template>

		<template #cell(Autumn)="data">
			<div :style="{ backgroundColor: data.item.color, padding: '5px' }">
				<valid-input
					:vid="`${data.item.codeName}_${data.field.label}`"
					:inputType="'number'"
					:inputValue.sync="data.item.Autumn"
					:classList="'text-right'"
					:rules="rules.COST_ORIGIN_LANG"
					:errorMessage="validMessage.COMMON_DECIMAL_NUMBER_LENGTH_VALID_MESSAGE(6)"
				></valid-input>
				<!-- <input type="number" class="form-control text-right" v-model= "data.item.Autumn"/>  -->
			</div>
		</template>

		<template #cell(Fall)="data">
			<div :style="{ backgroundColor: data.item.color, padding: '5px' }">
				<valid-input
					:vid="`${data.item.codeName}_${data.field.label}`"
					:inputType="'number'"
					:inputValue.sync="data.item.Fall"
					:classList="'text-right'"
					:rules="rules.COST_ORIGIN_LANG"
					:errorMessage="validMessage.COMMON_DECIMAL_NUMBER_LENGTH_VALID_MESSAGE(6)"
				></valid-input>
				<!-- <input type="number" class="form-control text-right" v-model= "data.item.Fall"/>  -->
			</div>
		</template>
	</b-table>
</template>

<style>
	.td-custom-tag-code {
		width: 100px;
	}

	.td-custom-pd {
		padding: 0px !important;
	}
</style>

<script>
	import * as rules from "@src/consts/ruleConsts.js";
	import * as validMessage from "@src/consts/validMessageConsts";

	export default {
		components: {},
		props: ["isRegist", "costTable"],
		data() {
			return {
				fields: null,
				items: [],
				// sesnDvsnCodeOption : [],
				emptyText: "",
				selected: [],

				// sesnDvsnCodeList: null,
				tagCodeList: null,
				rules,
				validMessage,
			};
		},
		watch: {
			costTable() {
				this.setData(this.costTable);
			},
		},
		created() {
			// this.getTagCode();
			// this.getSesnDvsnCode();
			// this.setFields();
			console.log("created");
			this.setData(this.costTable);
		},
		mounted() {
			console.log("mounnted");
		},
		updated() {},
		methods: {
			setData(data) {
				console.log(data);
        
        if(this.isEmpty(data)) return;

				this.setFields(data.sesnDvsnInfo);
				this.setItems(data.costDetailInfo, data.tagInfo);

				// this.setFields(tableCode);
				// this.setItems(tableCode,costTable);
			},
			setFields(sesnDvsnInfo) {
				this.fields = [{ key: "codeName", label: "", tdClass: "td-custom-tag-code td-custom-pd" }];
				this.setItemSesnDvsnCode(sesnDvsnInfo);
			},
			setItemSesnDvsnCode(sesnDvsnInfo) {
				sesnDvsnInfo.forEach((data) => {
					this.fields.push({ key: data.code, label: global.xe.$t(data.codeName), tdClass: "td-custom-pd" });
				});
			},
			setItems(costDetailInfo, tagInfo) {
				this.items = [];
				this.setItemTagCode(tagInfo);

				this.items.forEach((item) => {
					// console.log(item.code.charAt(0).toLowerCase() + item.code.slice(1))
					let detailInfos = Object.keys(costDetailInfo);

					let targetCostDetailInfo = costDetailInfo[detailInfos.find((v) => v.toLowerCase() === item.code.toLowerCase())];
					// console.log(targetCostDetailInfo);
					if (targetCostDetailInfo) {
						targetCostDetailInfo.forEach((info) => {
							item[info.sesnDvsnCode] = info.costValue;
						});
					}
				});

				console.log(this.items);
			},
			setItemTagCode(tagInfo) {
				console.log(tagInfo);
				tagInfo.forEach((info) => {
					// this.items.push({"tagCode" : tagCode.smallCodeName});
					this.items.push({ code: info.code, codeName: info.codeName, color: info.color });
				});
			},
			onChangeColor(row) {
				let tagCode = null;

				this.tagCodeList.forEach((code) => {
					if (row.item.tagCode == code.smallCodeName) {
						// resultData tagCode와 코드테이블 tag코드 대소문자가 다름
						// resultData에 맞히기 위해서 앞첫글자만 소문자로 변환 진행
						tagCode = code.smallCode;
					}
				});
				console.log(tagCode);
				this.$refs.costTable.selectRow(row.index);
			},
			getParam() {
				let param = {
					costBasicCode: null,
					costDvsnCode: this.getCostDvsnCode(),
					sesnDvsnCode: null,
					tagCode: null,
					value: null,
					color: null,
					css: null,
				};

				param;
			},
			getCostDvsnCode() {
				let result = this.$emit("getCostDvsnCode");
				return result;
			},
			getDetail() {
				let param = [];

				this.items.forEach((item) => {
					param.push(
						{ sesnDvsnCode: "Spring", tagCode: item.code.charAt(0).toLowerCase() + item.code.slice(1), costValue: item.Spring },
						{ sesnDvsnCode: "Summer", tagCode: item.code.charAt(0).toLowerCase() + item.code.slice(1), costValue: item.Summer },
						{ sesnDvsnCode: "Winter", tagCode: item.code.charAt(0).toLowerCase() + item.code.slice(1), costValue: item.Winter }
					);
				});

				return param;
			},
		},
	};
</script>
