<template>
  <div class="row">
      <div class="col-lg-12">
        <div class="pull-left">
          <h3>
            {{$t('운영 기준정보 관리')}} > {{ $t(title) }}
          </h3>
        </div>
      </div>
    </div>
</template>

<script>
// import backEndApi from "../../../../../api/backEndApi";

export default {
  components: {},
  props: ["title"],
  watch: {},
  data() {
    return {
      
    };
  },
  computed: {
   
  },
  created() {
  
  },
  methods: {
    
  },
};
</script>
