<template>
	<!-- 에너지원 -->
	<div class="font-weight-bold d-flex flex-row" :style="{ fontSize: '15px' }">
		<div class="pt-2 p-l-5">
			<span>{{$t('에너지원')}}</span>
		</div>
		<div class="p-l-5 p-r-5">
			<select class="form-control" :placeholder="$t('사용자 에너지원 선택')" ref="energySourceCode" v-model="energySourceCode" @change="onChanegeEnergySourceCode">
				<option value="" v-if="enableTotal">{{$t('전체')}}</option>
				<option v-for="(code, index) in smallCodeList" :key="index" :value="code.smallCode">
					{{ code.smallCodeName }}
				</option>
			</select>
		</div>
	</div>
</template>

<script>
	import backEndApi from "@api/backEndApi.js";

	export default {
		components: {},
		props: ["enableTotal"],
		watch: {},
		data() {
			return {
				energySourceCode: "",
				smallCodeList: null,
			};
		},
		computed: {},
		created() {},
		async mounted() {
			await this.getSmallCodeList();
		},
		updated() {},
		destroyed() {},
		methods: {
			async getSmallCodeList() {
				// 이전 리비전과 소스 에서 아래와 같이 변경함.
				/*           
        ↓↓↓↓↓
      */
				const result = await backEndApi.publicCode.getOprtnSmallCodeList("energySourceCode");
				this.smallCodeList = result.data.filter((v) => v.etcInfo2 === "Y");
				// console.log(this.smallCodeList);
        
				if (this.enableTotal) {
					this.smallCodeList.unshift({
						etcInfo2: "Y",
						largeCode: "energySourceCode",
						smallCode: "",
						smallCodeName: "전체",
					});
				}

        this.energySourceCode = this.smallCodeList[0].smallCode;

				this.onChanegeEnergySourceCode();
			},
			getValue() {
				return this.energySourceCode;
			},
			setValue(energySourceCode) {
				this.energySourceCode = energySourceCode;

				this.onChanegeEnergySourceCode();
			},
			onChanegeEnergySourceCode() {
				this.$emit("ready:data", this.getValue());
			},
			focus() {
				this.$refs.energySourceCode.focus();
			},
		},
	};
</script>

<style scoped></style>
