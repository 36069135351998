<template>
  <div>
    <div class="font-weight-bold d-flex flex-row m-t-20">
      <div class="w-100">
        <cost-calendar-table :ref="'costCalendarTable'" />
      </div>
    </div>
    <b-container class="font-weight-bold d-flex flex-row m-t-20">
      <b-form class="mb-2" inline>
        <div class="d-flex flex-row w-100" v-bind:style="{fonSize:'15px'}">
          <div class="mt-auto mb-auto w-100">
            <i class="material-icons align-middle m-b-2" >info</i>
            <strong class="mt-auto mb-auto w-50">{{$t('시간대 요금 구분')}}</strong>
          </div>
        </div>
      </b-form>
    </b-container>
    <b-container class="font-weight-bold d-flex flex-row">
      <cost-spring-detail-table :ref="'costSpringDetailTable'" :sesnDvsnCode="'Spring'" />
      <cost-summer-detail-table :ref="'costSummerDetailTable'" :sesnDvsnCode="'Summer'" />
      <cost-winter-detail-table :ref="'costWinterDetailTable'" :sesnDvsnCode="'Winter'" />
    </b-container>
  </div>
</template>

<script>
import "element-ui/lib/theme-chalk/index.css";
import backEndApi from "@/api/backEndApi";
import costCalendarTable from "@views/component/gridBox/management/cost/CostCalendarTable.vue";
import costSpringDetailTable from "@views/component/gridBox/management/cost/CostDetailTable.vue";
import costSummerDetailTable from "@views/component/gridBox/management/cost/CostDetailTable.vue";
import costWinterDetailTable from "@views/component/gridBox/management/cost/CostDetailTable.vue";
import * as popupMessages from "@src/consts/popupMessageConsts";
import AppErrorHandler from "@src/appErrorHandler";

export default {
  props: [],
  components: { 
    costCalendarTable,
    costSpringDetailTable,
    costSummerDetailTable,
    costWinterDetailTable
  },
  watch: {
    
  },
  data() {
    return {
      popupMessages
    };
  },
  created() {
  },
  mounted() {
    
  },
  methods: {
    changeEnergySourceCode(energySourceCode){
      this.$refs.costBasicCodeComboBox.getCostBasicCodeList(energySourceCode);
    },
    changeCostBasicCode(costBasicCode){
      this.$refs.costDvsnCodeComboBox.getCostDvsnCodeList(costBasicCode);
    },
    submit(data) {

      // TODO: tagInfo의 Color Property 변경

      // 요금테이블
      // this.$refs.costTable.setData(data.tableCode, data.costTable);
      
      // 요금달력 데이터
      this.$refs.costCalendarTable.setItems(data.costInfo,data.costCalendar); 

      // 요금상세 - 봄/가을철, 여름철, 겨울철
      this.$refs.costSpringDetailTable.setInitData(data.costInfo, data.costDetail.Spring, data.costInfo.tagInfo);
      this.$refs.costSummerDetailTable.setInitData(data.costInfo, data.costDetail.Summer, data.costInfo.tagInfo);
      this.$refs.costWinterDetailTable.setInitData(data.costInfo, data.costDetail.Winter, data.costInfo.tagInfo);
    },
    async save() {
      // 계절구분요금 파라미터 validation check진행
      if (this.$refs.costCalendarTable.isValidation()) {
        // 계절구분요금 저장 진행
        if (this.$refs.costCalendarTable.onSave()) {
          // 시간대 요금 저장
          let param = [];

          this.$refs.costSpringDetailTable.getParam(param);
          this.$refs.costSummerDetailTable.getParam(param);
          this.$refs.costWinterDetailTable.getParam(param);

          if (this.$refs.costWinterDetailTable.isValidation(param)) {
            if (param.length == 72) {

              // 이전 리비전과 소스 에서 아래와 같이 변경함.
              /*           
                ↓↓↓↓↓
              */
              try {
                await backEndApi.cost.insertCostDetail(param);
                console.log("시간대 요금구분 등록 > 성공");
                this.alertNoti(popupMessages.COST_SAVE_POPUP_MESSAGE);

              } catch (err) {
                new AppErrorHandler(err)
                  .printErrorLog()
                  .errHandling();
              }
            }
          } else {
            this.alertNoti(popupMessages.COST_SAVE_TIME_VALID_POPUP_MESSAGE);
          }
        } else {
          this.alertNoti(popupMessages.COMMON_SAVE_ERROR_POPUP_MESSAGE);
        }
      }
    },
    getParam(type){
      if(type == "costTable"){
        return this.$refs.costTable.getParam();
      }
    },
    getCostDvsnCode(){
      // 요금구분코드 가져오기
      let costDvsnCode = this.$emit("getCostDvsnCode");
      return costDvsnCode;
    }
  }
};
</script>

<style scoped>

</style>
