<template>
	<div class="font-weight-bold flex-row" :style="{ fontSize: '15px' }">
		<div class="p-l-5 p-r-5">
			<select class="form-control" :placeholder="$t('선택')" ref="regIdx" v-model="regIdx" @change="changeRegIdxComboBox">
				<option value="">{{$t('선택')}}</option>
				<option value="new">{{$t('신규')}}</option>
				<option v-for="(data, index) in regList" :key="index" :value="data.regIdx">
					{{ data.regName }}
				</option>
			</select>
		</div>
	</div>
</template>

<script>
	import backEndApi from "@api/backEndApi.js";
	import AppErrorHandler from "@src/appErrorHandler";

	export default {
		components: {},
		props: [],
		watch: {},
		data() {
			return {
				regIdx: "",
				regList: null,
			};
		},
		computed: {},
		created() {},
		mounted() {},
		updated() {},
		destroyed() {},
		methods: {
			async getRegList(energySourceCode) {
        console.log(energySourceCode);
				// 이전 리비전과 소스 에서 아래와 같이 변경함.
				/*           
        ↓↓↓↓↓
      */
				try {
					const result = await backEndApi.cost.getRegList(energySourceCode);
          console.log(result)
					this.regList = result.data;
					if (result.data.length !== 0) {
						this.regIdx = result.data[0].regIdx;
						this.changeRegIdxComboBox();
					}
					// this.regIdx = data[0].regIdx;
					// this.regList = data;
					// this.changeRegIdxComboBox();
					//   this.$emit("change:costBasicCode",data[0].costBasicCode);
				} catch (err) {
					this.regList = null;
					//   this.$emit("change:costBasicCode",null);
					new AppErrorHandler(err).printErrorLog().errHandling();
				}
			},
			getValue() {
				return this.regIdx;
			},
			setValue(regIdx) {
				if (regIdx == "") {
					this.regList = null;
				}

				this.regIdx = regIdx;
				this.changeRegIdxComboBox();
			},
			focus() {
				this.$refs.regIdx.focus();
			},
			changeRegIdxComboBox() {
				this.$emit("changeRegIdx");
			},
		},
	};
</script>

<style scoped></style>
