<template>
	<div v-if="data.length > 0 && options.length > 0 && columnOptions" :style="{ width: '31%', marginRight: '3.5%' }">
		<div class="rounded-top pl-2 p-t-5 bg-dark text-white w-50" :style="{ height: '30px' }">
			{{ sesnDvsnCode == "Spring" ? `${$t("봄•가을철")} ${$t("부하구분")}` : sesnDvsnCode == "Summer" ? `${$t("여름철")} ${$t("부하구분")}` : `${$t("겨울철")} ${$t("부하구분")}` }}
		</div>
		<slim-grid
			:class="'rounded'"
			:height="634"
			:show-pager="false"
			:show-pager-stats="false"
			:downloadable="false"
			:editable="false"
			:data="data"
			:column-options="columnOptions"
			:context-menu-options="options"
			@context-menu-option-selected="onCtxMenuOptSelected"
			@selected-ranges-changed="onSelectedRangesChanged"
		/>
	</div>
</template>

<style lang="scss">
	.base-color {
		$bg: #29101e;

		background-color: $bg;
		color: a11y-color(#462d3b, $bg);
	}

	.slim-context-menu {
		position: fixed;
	}
</style>

<style>
	.td-custom-pd {
		padding: 0px !important;
	}
	.td-custom-base-hour {
		background-color: #e9ecef;
		color: #495057;
		width: 70px;
	}

	.slick-viewport {
		height: 630px !important;
	}

	.slick-headerrow {
		/* 이전에는 없던 header가 출력됨  */
		display: none;
	}

	/* .slick-range-decorator {
  width: 100% !important;
} */

	.slick-track {
		display: flex !important;
	}

	.slick-slide {
		height: auto;
	}

	.slick-cell {
		margin: 0px;
		padding: 0px;
	}

	.slick-cell.slick-cell.selected {
		opacity: 0.5;
	}

	.th-custom-color {
		color: #fff !important;
		background-color: #343a40 !important;
		border-color: #454d55 !important;
		font-weight: 700;
	}

	.th-custom-width {
		width: 84px !important;
		color: #fff !important;
		background-color: #343a40 !important;
		border-color: #454d55 !important;
		font-weight: 700;
	}

	/* .td-custom-width {
  width: 298px !important;
} */
</style>

<script>
	import backEndApi from "@api/backEndApi";
	import SlimGrid from "vue-slimgrid";
	import { Editors } from "slickgrid-es6";
	import AppErrorHandler from "@src/appErrorHandler";

	export default {
		components: { SlimGrid },
		props: ["sesnDvsnCode"],
		data() {
			return {
				initData: null,
				data: [],
				columnOptions: null,
				options: [],
				selected: null,
				costBasicCode: null,
				costDvsnCode: null,
				energySourceCode: null,
				hoilyCostUseType: false,
				contextMenuList: null,
			};
		},
		watch: {
			// data(){
			//   // this.isWkdHlyFlag();
			//   // this.setFields();
			//   // this.setItems();
			// }
		},
		computed: {},
		created() {},
		mounted() {},
		updated() {},
		methods: {
			// context 스타일링 테스트 함수

			setInitData(costInfo, resultData, tagInfo) {
				this.setCode(costInfo);

				if (!resultData) {
					resultData = this.setDefalutResultData();
				}

				this.setColor(resultData, tagInfo);

				this.isHoilyCostUseType(costInfo.hoilyCostUseType);

				this.setData(resultData);
				this.setOptions(tagInfo);
				this.setColumnOptions();
			},
			setCode(costInfo) {
				this.costBasicCode = costInfo.costBasicCode;
				this.costDvsnCode = costInfo.costDvsnCode;
				this.energySourceCode = costInfo.energySourceCode;
			},
			setColor(resultData, tagInfo) {
				if (resultData) {
					resultData.forEach((data) => {
						tagInfo.forEach((tag) => {
							if (data.baseTagCode == tag.code) {
								data.baseColor = tag.color;
							}
							if (data.wkdTagCode == tag.code) {
								data.wkdColor = tag.color;
							}
							if (data.hlyTagCode == tag.code) {
								data.hlyColor = tag.color;
							}
						});
					});
				}

				this.initData = resultData;
			},
			setData(resultData) {
				this.data = [];

				if (this.hoilyCostUseType) {
					resultData.forEach((resultData, index) => {
						let newObject = {};
						newObject["id"] = index;
						newObject[global.xe.$t("기준 시간")] = resultData.baseHour + " ~";
						newObject[global.xe.$t("평일")] = resultData.baseTagCodeName;
						newObject[global.xe.$t("토요일")] = resultData.wkdTagCodeName;
						newObject[global.xe.$t("휴일")] = resultData.hlyTagCodeName;

						this.data.push(newObject);

						// this.data.push({
						// 	id: index,
						// 	기준시간: resultData.baseHour + " ~",
						// 	평일: resultData.baseTagCodeName,
						// 	토요일: resultData.wkdTagCodeName,
						// 	휴일: resultData.hlyTagCodeName,
						// });
					});
				} else {
					resultData.forEach((resultData, index) => {
						let newObject = {};
						newObject["id"] = index;
						newObject[global.xe.$t("기준 시간")] = resultData.baseHour + " ~";
						newObject[global.xe.$t("평일")] = resultData.baseTagCodeName;

						this.data.push(newObject);

						// this.data.push({
						// 	id: index,
						// 	기준시간: resultData.baseHour + " ~",
						// 	평일: resultData.baseTagCodeName,
						// });
					});
				}
			},
			setDefalutResultData(resultData) {
				resultData = [
					{ baseHour: "00", baseTagCode: "", baseTagCodeName: "", wkdTagCode: "", wkdTagCodeName: "", hlyTagCode: "", hlyTagCodeName: "" },
					{ baseHour: "01", baseTagCode: "", baseTagCodeName: "", wkdTagCode: "", wkdTagCodeName: "", hlyTagCode: "", hlyTagCodeName: "" },
					{ baseHour: "02", baseTagCode: "", baseTagCodeName: "", wkdTagCode: "", wkdTagCodeName: "", hlyTagCode: "", hlyTagCodeName: "" },
					{ baseHour: "03", baseTagCode: "", baseTagCodeName: "", wkdTagCode: "", wkdTagCodeName: "", hlyTagCode: "", hlyTagCodeName: "" },
					{ baseHour: "04", baseTagCode: "", baseTagCodeName: "", wkdTagCode: "", wkdTagCodeName: "", hlyTagCode: "", hlyTagCodeName: "" },
					{ baseHour: "05", baseTagCode: "", baseTagCodeName: "", wkdTagCode: "", wkdTagCodeName: "", hlyTagCode: "", hlyTagCodeName: "" },
					{ baseHour: "06", baseTagCode: "", baseTagCodeName: "", wkdTagCode: "", wkdTagCodeName: "", hlyTagCode: "", hlyTagCodeName: "" },
					{ baseHour: "07", baseTagCode: "", baseTagCodeName: "", wkdTagCode: "", wkdTagCodeName: "", hlyTagCode: "", hlyTagCodeName: "" },
					{ baseHour: "08", baseTagCode: "", baseTagCodeName: "", wkdTagCode: "", wkdTagCodeName: "", hlyTagCode: "", hlyTagCodeName: "" },
					{ baseHour: "09", baseTagCode: "", baseTagCodeName: "", wkdTagCode: "", wkdTagCodeName: "", hlyTagCode: "", hlyTagCodeName: "" },
					{ baseHour: "10", baseTagCode: "", baseTagCodeName: "", wkdTagCode: "", wkdTagCodeName: "", hlyTagCode: "", hlyTagCodeName: "" },
					{ baseHour: "11", baseTagCode: "", baseTagCodeName: "", wkdTagCode: "", wkdTagCodeName: "", hlyTagCode: "", hlyTagCodeName: "" },
					{ baseHour: "12", baseTagCode: "", baseTagCodeName: "", wkdTagCode: "", wkdTagCodeName: "", hlyTagCode: "", hlyTagCodeName: "" },
					{ baseHour: "13", baseTagCode: "", baseTagCodeName: "", wkdTagCode: "", wkdTagCodeName: "", hlyTagCode: "", hlyTagCodeName: "" },
					{ baseHour: "14", baseTagCode: "", baseTagCodeName: "", wkdTagCode: "", wkdTagCodeName: "", hlyTagCode: "", hlyTagCodeName: "" },
					{ baseHour: "15", baseTagCode: "", baseTagCodeName: "", wkdTagCode: "", wkdTagCodeName: "", hlyTagCode: "", hlyTagCodeName: "" },
					{ baseHour: "16", baseTagCode: "", baseTagCodeName: "", wkdTagCode: "", wkdTagCodeName: "", hlyTagCode: "", hlyTagCodeName: "" },
					{ baseHour: "17", baseTagCode: "", baseTagCodeName: "", wkdTagCode: "", wkdTagCodeName: "", hlyTagCode: "", hlyTagCodeName: "" },
					{ baseHour: "18", baseTagCode: "", baseTagCodeName: "", wkdTagCode: "", wkdTagCodeName: "", hlyTagCode: "", hlyTagCodeName: "" },
					{ baseHour: "19", baseTagCode: "", baseTagCodeName: "", wkdTagCode: "", wkdTagCodeName: "", hlyTagCode: "", hlyTagCodeName: "" },
					{ baseHour: "20", baseTagCode: "", baseTagCodeName: "", wkdTagCode: "", wkdTagCodeName: "", hlyTagCode: "", hlyTagCodeName: "" },
					{ baseHour: "21", baseTagCode: "", baseTagCodeName: "", wkdTagCode: "", wkdTagCodeName: "", hlyTagCode: "", hlyTagCodeName: "" },
					{ baseHour: "22", baseTagCode: "", baseTagCodeName: "", wkdTagCode: "", wkdTagCodeName: "", hlyTagCode: "", hlyTagCodeName: "" },
					{ baseHour: "23", baseTagCode: "", baseTagCodeName: "", wkdTagCode: "", wkdTagCodeName: "", hlyTagCode: "", hlyTagCodeName: "" },
				];
				return resultData;
			},
			setOptions(tagInfo) {
				// this.options = [];
				this.options.splice(0, this.options.length);

				tagInfo.forEach((tag) => {
					this.options.push({ label: tag.codeName, color: tag.color, code: tag.code });
				});
			},
			getTextColorByBackgroundColor(hexColor) {
				const c = hexColor.substring(1); // 색상 앞의 # 제거
				const rgb = parseInt(c, 16); // rrggbb를 10진수로 변환
				let r = (rgb >> 16) & 0xff; // red 추출
				let g = (rgb >> 8) & 0xff; // green 추출
				let b = (rgb >> 0) & 0xff; // blue 추출

				// if(0.2126 * r <= 0.03928){
				//    r = r / 12.92;
				// }else{
				//    r = Math.pow(((r+0.055) / 1.055),2.4);
				// }

				// if(0.7152 * g <= 0.03928){
				//    g = g / 12.92;
				// }else{
				//    g = Math.pow(((g+0.055) / 1.055),2.4);
				// }

				// if(0.0722  * b <= 0.03928){
				//    b = b / 12.92;
				// }else{
				//    b = Math.pow(((b+0.055) / 1.055),2.4);
				// }

				// r = r / 255;
				// g = g / 255;
				// b = b / 255;

				// return c+r+g+b;

				const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
				// 색상 선택
				return luma < 127.5 ? "white" : "black"; // 글자색이
			},
			setColumnOptions() {
				// this.columnOptions = null;

				this.columnOptions = {};
				this.columnOptions["*"] = {
					sortable: false,
					headerInput: false,
					headerFilter: false,
					defaultSortAsc: false,
					resizable: false,
					editor: Editors.Text,
				};
				this.columnOptions[global.xe.$t("기준 시간")] = {
					maxWidth: 60,
					selectable: false,
					headerCssClass: "th-custom-color",
				};
				this.columnOptions[global.xe.$t("평일")] = {
					headerCssClass: "th-custom-color",
					// cssClass: !this.hoilyCostUseType ? "td-custom-width" : ""
					formatter: (row, cell, value, columnDef, dataContext) => {
						row, cell, value, columnDef, dataContext;

						let color = "#ffffff";

						if (this.initData[row].baseTagCodeName == value) {
							color = this.initData[row].baseColor;
						}

						columnDef.minWidth = 93;
						columnDef.width = 93;

						if (!this.hoilyCostUseType) {
							columnDef.minWidth = 278;
							columnDef.width = 278;
						}

						if (!color) {
							color = "#ffffff";
						}

						let fontColor = this.getTextColorByBackgroundColor(color);

						return `<div class="text-center" style="background-color: ${color}; color: ${fontColor}; font-size: 8pt; padding: 3px; ">${value}</div>`;
						// return `<div class="text-center base-color" style="font-size: 8pt; padding: 3px; ">${value}</div>`;
					},
				};
				this.columnOptions[global.xe.$t("토요일")] = {
					headerCssClass: !this.hoilyCostUseType ? "th-custom-color" : "th-custom-width",
					// cssClass: !this.hoilyCostUseType ? "td-custom-width" : "",
					hidden: !this.hoilyCostUseType,
					formatter: (row, cell, value, columnDef, dataContext) => {
						row, cell, value, columnDef, dataContext;

						let color = "#ffffff";

						if (this.initData[row].wkdTagCodeName == value) {
							color = this.initData[row].wkdColor;
						}

						columnDef.minWidth = 93;
						columnDef.width = 93;

						if (!color) {
							color = "#ffffff";
						}

						let fontColor = this.getTextColorByBackgroundColor(color);

						return `<div class="text-center" style="background-color: ${color}; color: ${fontColor}; font-size: 8pt; padding: 3px; ">${value}</div>`;
					},
				};
				this.columnOptions[global.xe.$t("휴일")] = {
					headerCssClass: !this.hoilyCostUseType ? "th-custom-color" : "th-custom-width",
					// cssClass: !this.hoilyCostUseType ? "td-custom-width" : "",
					hidden: !this.hoilyCostUseType,
					formatter: (row, cell, value, columnDef, dataContext) => {
						row, cell, value, columnDef, dataContext;

						let color = "#ffffff";

						if (this.initData[row].hlyTagCodeName == value) {
							color = this.initData[row].hlyColor;
						}

						columnDef.minWidth = 93;
						columnDef.width = 93;

						if (!color) {
							color = "#ffffff";
						}

						let fontColor = this.getTextColorByBackgroundColor(color);

						return `<div class="text-center" style="background-color: ${color}; color: ${fontColor}; font-size: 8pt; padding: 3px; ">${value}</div>`;
					},
				};

				// this.columnOptions = {
				// 	"*": ,
				// 	기준시간: ,
				// 	평일: ,
				// 	토요일: ,
				// 	휴일: ,
				// };
			},
			onCtxMenuOptSelected(args) {
				args;

				for (let row = this.selected.fromRow; row < this.selected.toRow + 1; row++) {
					for (let col = this.selected.fromCell; col < this.selected.toCell + 1; col++) {
						// console.log('>>>', JSON.stringify(this.timeTbl.refs[row][col]));

						if (col == 1) {
							this.initData[row].baseTagCodeName = args.option.label;
							this.initData[row].baseTagCode = args.option.code;
							this.initData[row].baseColor = args.option.color;
						} else if (col == 2) {
							this.initData[row].wkdTagCodeName = args.option.label;
							this.initData[row].wkdTagCode = args.option.code;
							this.initData[row].wkdColor = args.option.color;
						} else if (col == 3) {
							this.initData[row].hlyTagCodeName = args.option.label;
							this.initData[row].hlyTagCode = args.option.code;
							this.initData[row].hlyColor = args.option.color;
						}
					}
				}

				this.setData(this.initData);
			},
			onSelectedRangesChanged(e, args) {
				e;
				let grid = args[0];

				if (grid) {
					this.selected = { fromCell: grid.fromCell, toCell: grid.toCell, fromRow: grid.fromRow, toRow: grid.toRow };
				}

				args.clear();
			},
			isHoilyCostUseType(hoilyCostUseType) {
				if (hoilyCostUseType == "Y") {
					this.hoilyCostUseType = true;
				} else {
					this.hoilyCostUseType = false;
				}
			},
			async onSave(param) {
				let result = true;

				// 이전 리비전과 소스 에서 아래와 같이 변경함.
				/*           
				↓↓↓↓↓
			*/
				try {
					await backEndApi.cost.insertCostDetail(param);
					result = true;
				} catch (err) {
					result = false;
					new AppErrorHandler(err).printErrorLog().errHandling();
				}
				return result;
			},
			getParam(param) {
				this.initData.forEach((data) => {
					param.push({
						costBasicCode: this.costBasicCode,
						costDvsnCode: this.costDvsnCode,
						sesnDvsnCode: this.sesnDvsnCode,
						baseHour: data.baseHour,
						baseTagCode: data.baseTagCode,
						wkdTagCode: data.wkdTagCode,
						hlyTagCode: data.hlyTagCode,
					});
				});
				return param;
			},
			isValidation(param) {
				// 봄가을, 여름, 겨울 flag 설정
				let flag = true;

				// 파라미터 validation check
				param.forEach((data) => {
					if (this.hoilyCostUseType) {
						if (!data.wkdTagCode) {
							flag = false;
						}

						if (!data.hlyTagCode) {
							flag = false;
						}
					}

					if (!data.baseTagCode) {
						flag = false;
					}
				});

				return flag;
			},
			// isValidation(){

			//   // 파라미터 초기값 설정
			//   let param = [];

			//   // 파라미터 설정
			//   this.initData.forEach(data => {
			//     param.push(
			//       {
			//         costBasicCode : this.costBasicCode,
			//         costDvsnCode : this.costDvsnCode,
			//         sesnDvsnCode : this.sesnDvsnCode,
			//         baseHour : data.baseHour,
			//         baseTagCode : data.baseTagCode,
			//         wkdTagCode : data.wkdTagCode,
			//         hlyTagCode : data.hlyTagCode
			//       }
			//     )
			//   });

			//   // 봄가을, 여름, 겨울 flag 설정
			//   let baseflag = true; let wkdflag = true; let hlyflag = true;

			//   // 파라미터 validation check
			//   param.forEach(data => {
			//     if(this.sesnDvsnCode == "Spring"){
			//       if(data.baseTagCode == ""){
			//           baseflag = false;
			//       }

			//       if(this.hoilyCostUseType){
			//         if(data.wkdTagCode == ""){
			//           wkdflag = false;
			//         }else if(data.hlyTagCode == ""){
			//           hlyflag = false;
			//         }
			//       }
			//     }else if(this.sesnDvsnCode == "Summer"){
			//       if(data.baseTagCode == ""){
			//           baseflag = false;
			//       }

			//       if(this.hoilyCostUseType){
			//         if(data.wkdTagCode == ""){
			//           wkdflag = false;
			//         }else if(data.hlyTagCode == ""){
			//           hlyflag = false;
			//         }
			//       }
			//     }else if(this.sesnDvsnCode == "Winter"){
			//       if(data.baseTagCode == ""){
			//           baseflag = false;
			//       }
			//       if(this.hoilyCostUseType){
			//         if(data.wkdTagCode == ""){
			//           wkdflag = false;
			//         }else if(data.hlyTagCode == ""){
			//           hlyflag = false;
			//         }
			//       }
			//     }
			//   });

			//   // 파라미터에 validation false 시
			//   // 파라미터 초기화 진행
			//   if(this.sesnDvsnCode == "Spring"){
			//       if(!baseflag){
			//       param = [];
			//     }else if(!wkdflag){
			//       param = [];
			//     }else if(!hlyflag){
			//       param = [];
			//     }
			//   }else if(this.sesnDvsnCode == "Summer"){
			//     if(!baseflag){
			//       param = [];
			//     }else if(!wkdflag){
			//       param = [];
			//     }else if(!hlyflag){
			//       param = [];
			//     }
			//   }else if(this.sesnDvsnCode == "Winter"){
			//     if(!baseflag){
			//       param = [];
			//     }else if(!wkdflag){
			//       param = [];
			//     }else if(!hlyflag){
			//       param = [];
			//     }
			//   }

			//   return param;
			// },
			setFields() {
				if (this.hoilyCostUseType) {
					this.fields = [
						{ key: "baseHour", label: global.xe.$t("기준 시간"), tdClass: "td-custom-pd td-custom-base-hour" },
						{ key: "baseTagCodeName", label: global.xe.$t("평일"), tdClass: "td-custom-pd" },
						{ key: "wkdTagCodeName", label: global.xe.$t("토요일"), tdClass: "td-custom-pd" },
						{ key: "hlyTagCodeName", label: global.xe.$t("휴일"), tdClass: "td-custom-pd" },
					];
				} else {
					this.fields = [
						{ key: "baseHour", label: global.xe.$t("기준 시간"), tdClass: "td-custom-pd td-custom-base-hour" },
						{ key: "baseTagCodeName", label: global.xe.$t("평일"), tdClass: "td-custom-pd" },
					];
				}
			},

			setItems() {
				this.items = null;
				this.items = this.data;
			},
		},
	};
</script>
