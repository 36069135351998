<template>
  <b-container>
    <b-form class="mb-2" inline>
      <div class="d-flex flex-row w-100" v-bind:style="{fonSize:'15px'}">
        <div class="mt-auto mb-auto w-75">
          <i class="material-icons align-middle m-b-2" >info</i>
          <strong class="mt-auto mb-auto w-50">{{$t('계절 요금 구분')}}</strong>
        </div>
      </div>
    </b-form>
    <b-table 
      no-border-collapse
      head-variant="dark"
      show-empty
      sticky-header="180px"
      ref="CostCalendarTable"
      :style="'min-width:100%; z-index:0;'"
      :class="'text-center mb-0'"
      :items="items"
      :fields="fields"
      :empty-text="$t('조회된 결과가 없습니다.')"
      table-variant = "light"
     >

      <template #empty="">
        <h4>{{ '' }}</h4>
      </template>

      <template v-slot:cell(baseMonth)="row">
        {{ $t(Number(row.item.baseMonth) + '월') }}
      </template>

      <template v-slot:cell(sesnDvsnCode)="row">
        <select class="form-control" v-model="row.item.sesnDvsnCode" >
          <option value="">{{$t('선택')}}</option>
          <option v-for="(code, index) in smallCodeList" :key="index" :value="code.smallCode">
            {{ code.smallCodeName }}
          </option>
        </select>
      </template>

        <template v-slot:cell(sesnDvsnCode01)="row">
          <select class="form-control" ref="sesnDvsnCode01" v-model="row.item.sesnDvsnCode01" @change="changeSesnDvsnCode($event.target.value,0)">
            <option value="">{{$t('선택')}}</option>
            <option v-for="(code, index) in smallCodeList" :key="index" :value="code.smallCode">
              {{ code.smallCodeName }}
            </option>
          </select>
        </template>
      
      <template v-slot:cell(sesnDvsnCode02)="row">
        <select class="form-control" ref="sesnDvsnCode02" v-model="row.item.sesnDvsnCode02" @change="changeSesnDvsnCode($event.target.value,1)">
            <option value="">{{$t('선택')}}</option>
            <option v-for="(code, index) in smallCodeList" :key="index" :value="code.smallCode">
              {{ code.smallCodeName }}
            </option>
          </select>
      </template>

      <template v-slot:cell(sesnDvsnCode03)="row">
        <select class="form-control" ref="sesnDvsnCode03" v-model="row.item.sesnDvsnCode03" @change="changeSesnDvsnCode($event.target.value,2)">
            <option value="">{{$t('선택')}}</option>
            <option v-for="(code, index) in smallCodeList" :key="index" :value="code.smallCode">
              {{ code.smallCodeName }}
            </option>
          </select>
      </template>

      <template v-slot:cell(sesnDvsnCode04)="row">
        <select class="form-control" ref="sesnDvsnCode04" v-model="row.item.sesnDvsnCode04" @change="changeSesnDvsnCode($event.target.value,3)">
            <option value="">{{$t('선택')}}</option>
            <option v-for="(code, index) in smallCodeList" :key="index" :value="code.smallCode">
              {{ code.smallCodeName }}
            </option>
          </select>
      </template>

      <template v-slot:cell(sesnDvsnCode05)="row">
        <select class="form-control" ref="sesnDvsnCode05" v-model="row.item.sesnDvsnCode05" @change="changeSesnDvsnCode($event.target.value,4)">
            <option value="">{{$t('선택')}}</option>
            <option v-for="(code, index) in smallCodeList" :key="index" :value="code.smallCode">
              {{ code.smallCodeName }}
            </option>
          </select>
      </template>

      <template v-slot:cell(sesnDvsnCode06)="row">
        <select class="form-control" ref="sesnDvsnCode06" v-model="row.item.sesnDvsnCode06" @change="changeSesnDvsnCode($event.target.value,5)">
          <option value="">{{$t('선택')}}</option>
          <option v-for="(code, index) in smallCodeList" :key="index" :value="code.smallCode">
            {{ code.smallCodeName }}
          </option>
        </select>
      </template>

      <template v-slot:cell(sesnDvsnCode07)="row">
        <select class="form-control" ref="sesnDvsnCode07" v-model="row.item.sesnDvsnCode07" @change="changeSesnDvsnCode($event.target.value,6)">
          <option value="">{{$t('선택')}}</option>
          <option v-for="(code, index) in smallCodeList" :key="index" :value="code.smallCode">
            {{ code.smallCodeName }}
          </option>
        </select>
      </template>

      <template v-slot:cell(sesnDvsnCode08)="row">
        <select class="form-control" ref="sesnDvsnCode08" v-model="row.item.sesnDvsnCode08" @change="changeSesnDvsnCode($event.target.value,7)">
          <option value="">{{$t('선택')}}</option>
          <option v-for="(code, index) in smallCodeList" :key="index" :value="code.smallCode">
            {{ code.smallCodeName }}
          </option>
        </select>
      </template>

      <template v-slot:cell(sesnDvsnCode09)="row">
        <select class="form-control" ref="sesnDvsnCode09" v-model="row.item.sesnDvsnCode09" @change="changeSesnDvsnCode($event.target.value,8)">
          <option value="">{{$t('선택')}}</option>
          <option v-for="(code, index) in smallCodeList" :key="index" :value="code.smallCode">
            {{ code.smallCodeName }}
          </option>
        </select>
      </template>

      <template v-slot:cell(sesnDvsnCode10)="row">
        <select class="form-control" ref="sesnDvsnCode10" v-model="row.item.sesnDvsnCode10" @change="changeSesnDvsnCode($event.target.value,9)">
          <option value="">{{$t('선택')}}</option>
          <option v-for="(code, index) in smallCodeList" :key="index" :value="code.smallCode">
            {{ code.smallCodeName }}
          </option>
        </select>
      </template>

      <template v-slot:cell(sesnDvsnCode11)="row">
        <select class="form-control" ref="sesnDvsnCode11" v-model="row.item.sesnDvsnCode11" @change="changeSesnDvsnCode($event.target.value,10)">
          <option value="">{{$t('선택')}}</option>
          <option v-for="(code, index) in smallCodeList" :key="index" :value="code.smallCode">
            {{ code.smallCodeName }}
          </option>
        </select>
      </template>

      <template v-slot:cell(sesnDvsnCode12)="row">
        <select class="form-control" ref="sesnDvsnCode12" v-model="row.item.sesnDvsnCode12" @change="changeSesnDvsnCode($event.target.value,11)">
          <option value="">{{$t('선택')}}</option>
          <option v-for="(code, index) in smallCodeList" :key="index" :value="code.smallCode">
            {{ code.smallCodeName }}
          </option>
        </select>
      </template>

      <template #cell(index)="data">
        {{ data.index + 1 }}
      </template>

    </b-table>
  </b-container>
</template>
 
<style>
.form-control-calendar{
  white-space: break-spaces;
  width: 38px;
  height: 100px;
  text-align: center;
  padding: 0px;

  font-size: .75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #2d353c;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d5dbe0;
  border-radius: 4px;
}
</style>

<script>
import backEndApi from "@api/backEndApi";
import AppErrorHandler from "@src/appErrorHandler";			
import * as popupMessages from "@src/consts/popupMessageConsts";

export default {
  components: {},
  props: [],
  data() {
    return {
      fields: null,
      items: null,

      emptyText: "",
      selected: [],

      smallCodeList: null,

      initData: null,

      costBasicCode: null,
      costDvsnCode: null,
      energySourceCode: null,
      popupMessages
    };
  },
  watch: {

  },
  async created() {
    await this.getSesnDvsnCode();
    this.setFields();
  },
  mounted() {
  },
  updated() {
  },
  methods: {
    async getSesnDvsnCode() {
      this.sesnDvsnCodeOption = [];

      // 이전 리비전과 소스 에서 아래와 같이 변경함.
      /*           
        ↓↓↓↓↓
      */
      try {
        const result = await backEndApi.publicCode.getSmallCodeList("sesnDvsnCode");
        this.smallCodeList = result.data;
      } catch {
      }
    },
    setFields() {
      this.fields = [
        { key: 'sesnDvsnCode01', label: global.xe.$t("1월") },
        { key: 'sesnDvsnCode02', label: global.xe.$t("2월") },
        { key: 'sesnDvsnCode03', label: global.xe.$t("3월") },
        { key: 'sesnDvsnCode04', label: global.xe.$t("4월") },
        { key: 'sesnDvsnCode05', label: global.xe.$t("5월") },
        { key: 'sesnDvsnCode06', label: global.xe.$t("6월") },
        { key: 'sesnDvsnCode07', label: global.xe.$t("7월") },
        { key: 'sesnDvsnCode08', label: global.xe.$t("8월") },
        { key: 'sesnDvsnCode09', label: global.xe.$t("9월") },
        { key: 'sesnDvsnCode10', label: global.xe.$t("10월") },
        { key: 'sesnDvsnCode11', label: global.xe.$t("11월") },
        { key: 'sesnDvsnCode12', label: global.xe.$t("12월") },
      ]
    },
    setItems(costInfo, resultData) {

      // 초기화
      this.items = [{}];

      // 기본 코드값 세팅
      this.setCode(costInfo);

      if (!(resultData.length > 0)) {
        resultData = this.setInitData();
      }

      resultData.forEach(data => {
        this.items[0]["sesnDvsnCode" + data.baseMonth] = data.sesnDvsnCode;
      });

      this.initData = resultData;
      // console.log(this.initData);
    },
    setCode(costInfo) {
      this.costBasicCode = costInfo.costBasicCode;
      this.costDvsnCode = costInfo.costDvsnCode;
      this.energySourceCode = costInfo.energySourceCode;
    },
    setInitData() {
      let result = [
        { costBasicCode: this.costBasicCode, costDvsnCode: this.costDvsnCode, baseMonth: "01", sesnDvsnCode: "" },
        { costBasicCode: this.costBasicCode, costDvsnCode: this.costDvsnCode, baseMonth: "02", sesnDvsnCode: "" },
        { costBasicCode: this.costBasicCode, costDvsnCode: this.costDvsnCode, baseMonth: "03", sesnDvsnCode: "" },
        { costBasicCode: this.costBasicCode, costDvsnCode: this.costDvsnCode, baseMonth: "04", sesnDvsnCode: "" },
        { costBasicCode: this.costBasicCode, costDvsnCode: this.costDvsnCode, baseMonth: "05", sesnDvsnCode: "" },
        { costBasicCode: this.costBasicCode, costDvsnCode: this.costDvsnCode, baseMonth: "06", sesnDvsnCode: "" },
        { costBasicCode: this.costBasicCode, costDvsnCode: this.costDvsnCode, baseMonth: "07", sesnDvsnCode: "" },
        { costBasicCode: this.costBasicCode, costDvsnCode: this.costDvsnCode, baseMonth: "08", sesnDvsnCode: "" },
        { costBasicCode: this.costBasicCode, costDvsnCode: this.costDvsnCode, baseMonth: "09", sesnDvsnCode: "" },
        { costBasicCode: this.costBasicCode, costDvsnCode: this.costDvsnCode, baseMonth: "10", sesnDvsnCode: "" },
        { costBasicCode: this.costBasicCode, costDvsnCode: this.costDvsnCode, baseMonth: "11", sesnDvsnCode: "" },
        { costBasicCode: this.costBasicCode, costDvsnCode: this.costDvsnCode, baseMonth: "12", sesnDvsnCode: "" }
      ];
      return result;
    },
    async onSave() {
      let reuslt = true;

      // 이전 리비전과 소스 에서 아래와 같이 변경함.
      /*           
        ↓↓↓↓↓
      */
      try {
        await backEndApi.cost.insertCalendar(this.initData);
        reuslt = true;
      } catch (err) {
        reuslt = false;
        
        new AppErrorHandler(err)
          .printErrorLog()
          .errHandling();
      }

      return reuslt;
    },
    isValidation() {
      let result = true;

      this.initData.forEach(data => {
        if (!result) {
          return false;
        }

        if(data.sesnDvsnCode == ""){
          this.$refs["sesnDvsnCode"+data.baseMonth].focus();
          this.alertNoti(data.baseMonth + popupMessages.COST_SAVE_SEASON_VALID_POPUP_MESSAGE);
          result = false;
        }
      });

      return result;
    },
    changeSesnDvsnCode(value, index) {
      this.initData[index].sesnDvsnCode = value;
    }
  },
};
</script>
