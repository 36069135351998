<template>
  <div class="col-xl-12 xeEnergyCostAnalysis">
    <header-box :title="'운영 코드 관리'"/>
    
    <div class="col-xl-12">
      <hr class="mt-3 mb-3"/>
      
      <div class="row">
        <search-box :ref="'search'" @search:data="onSearch" @save="save"/>
      </div>
      
      <div class="bg-white col-6 p-0">
        <grid-box ref="codeDetail" :data="items" @reSearch="reSearch" @getSaveItems="getSaveItems"/>
      </div>
      
    </div>
  </div>
</template>

<script>
import "element-ui/lib/theme-chalk/index.css";
import headerBox from "@/views/component/headerBox/management/Header.vue";
import searchBox from "./CodeInfoSearch.vue";
import gridBox from "./CodeInfoTable.vue";
import backEndApi from "@src/api/backEndApi";
import AppErrorHandler from "@src/appErrorHandler";
import * as popupMessages from "@src/consts/popupMessageConsts";

export default {
  name: "CodeInfo",
  props: ["type"],
  components: {
    headerBox,
    searchBox,
    gridBox
    // codeSearchBox,

  },
  data() {
    return {
      isRegist: true,
      items: null,
      selected: [],
      saveItems: [],
      searchLargeCode: '',
      popupMessages
    };
  },
  created() {
    console.log("management zone");
  },
  mounted() {
    // this.onSearch("energySourceCode");
  },
  methods: {
    getSaveItems(data) {
      this.saveItems = data;
    },
    onSearch({ result, largeCode }) {
      console.log("운영 코드 관리 조회");
      this.items = result;
      this.searchLargeCode = largeCode;
    },
    reSearch() {
      this.$refs.search.onSearch();
    },
    async save() {
      if (this.saveItems.length === 0) return;

      // validation check
      this.saveItems.forEach(data => {
        if(data.enabled === ""){
          this.alertNoti(popupMessages.COMMON_USAGE_VALID_POPUP_MESSAGE);
          return;
        }
      })


      for (let i = 0; i < this.saveItems.length; i++) {
        const item = this.saveItems[i];
        if (item.largeCode) {
          delete item.largeCode;
        }
      }

      const smallCodeData = this.saveItems;


      const sendData = {
        largeCode: this.searchLargeCode,
        smallCodeList: smallCodeData,
      }


      // 이전 리비전과 소스 에서 아래와 같이 변경함.
      /*           
        ↓↓↓↓↓
      */
      try {
        const result = await backEndApi.publicCode.updateOprtnCodeUseYn(sendData);
        this.alertNoti(result.data.message);
        this.reSearch();

      } catch (err) {
        new AppErrorHandler(err)
          .setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger)
          .errHandling();
      }
    },
    remove() {

    }
  }
};
</script>

<style scoped>

</style>
