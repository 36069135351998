<template>
  <div>
    <div class="row p-l-15 p-b-10">
      <div class="font-weight-bold d-flex flex-row" :style="{ fontSize: '15px'}">
        <div class="pt-2 p-l-15">
          <span>{{$t('운영코드')}}</span>
        </div>
        <div class="p-l-5">
          <select class="form-control" ref="enabled" @change="onSearch" v-model="searchValue.largeCode">
            <option v-for="(data, i) in OprtnCodeList" :key="`enabledCode${i}`" :value="data.largeCode">
            {{ data.largeCodeName }}
            </option>

          </select>
        </div>
      </div>
    
      <div class="font-weight-bold d-flex flex-row p-l-5" :style="{ fontSize: '15px'}">
        <button class="form-control" @click="onSearch">{{$t('조회')}}</button>
        <button class="form-control ml-1 mr-1" @click="save">{{$t('저장')}}</button>
        <!-- <button class="form-control" @click="onRemove">삭제</button> -->
      </div>
    </div>
  </div>
</template>

<script>
import backEndApi from "@/api/backEndApi";

export default {
  name: "CodeInfoSearch",
  props: [],
  components: {

  },
  watch: {

  },
  data() {
    return {
      OprtnCodeList: null,
      enabledCodeList: [],
      searchValue: {
        largeCode: "locPointUsage",
      }
    }
  },
  created() {
    // 처음 진입 시에만 호출
    this.init();
  },
  mounted() {
  },
  methods: {
    async init() {
      // 대분류코드 목록
      await this.getOprtnCodeList();
    },
    insertRow() {
      let objDiv = document.getElementsByClassName("pulibcTableClass");
      objDiv[0].scrollTop = objDiv[0].scrollHeight + 100;
      this.$emit("insertRow");
    },
    async getOprtnCodeList() {

      // 이전 리비전과 소스 에서 아래와 같이 변경함.
      /*           
        ↓↓↓↓↓
      */
      const result = await backEndApi.publicCode.getOprtnCodeList();
      this.OprtnCodeList = result.data;
      this.onSearch();
    },

    async onSearch() {

      // 운영에서는 detailCodeList를 사용할 것
      // smallCodeList는 조회에서만 사용


      // 이전 리비전과 소스 에서 아래와 같이 변경함.
      /*           
        ↓↓↓↓↓
      */
      let result = [];
      try {
        const res = await backEndApi.publicCode.getOprtnDetailCodeList(this.searchValue.largeCode);
        result = res.data;
      } catch (err) {
        result = [];
      }

      const largeCode = this.searchValue.largeCode;
      // const keyword = this.searchValue.codeName;                     // 대분류코드
      // const enabled = this.searchValue.enabled;                      // 사용여부   

      // var result = res.data.filter(data => enabled == "" ? data.enabled == data.enabled : data.enabled == enabled)

      this.$emit("search:data", { result, largeCode });
    },
    save() {
      this.$emit("save");
    },
    remove() {
      this.$emit("remove");
    },

  }
};
</script>

<style scoped>

</style>
