<template>
  <div class="col-xl-12 xeEnergyCostAnalysis">
    <header-box :title="$t('구역 관리')"/>

    <hr class="mt-3 mb-3"/>
    
    <div class="row">
      
      <div class="col-xl-12 col-lg-6">
        <div class="rounded" :style="'max-height:762px; min-height:762px; min-width:100%;'">
          <div class="p-15 ml-3 mr-3">
            <zone-search-box ref="search" :type="type" @search:enabled="onEnabled" @new="onNew"/>
            <zone-grid-box ref="grid" v-if="treeData.length > 0" :data="treeData" @reSearch="reSearch" :enabled="enabled"
                @newModal:zoneIdx="onNew"
              />
          </div>
        </div>
      </div>

      <b-modal ref="newModal" size="sm" :hide-footer="true" :title="$t('신규 구역')">
        <new-modal :zoneIdx="zoneIdx" @reSearch="reSearch" @hide="onHide"/>
      </b-modal>
    </div>
  </div>
</template>

<script>
import "element-ui/lib/theme-chalk/index.css";
import backEndApi from "../../../api/backEndApi";
import headerBox from "../../component/headerBox/management/Header.vue";
import zoneSearchBox from "./ZoneInfoSearch.vue";
import zoneGridBox from "../../component/gridBox/management/zone/ZoneGrid.vue";
import newModal from "./modal/newZoneInfo.vue"
import AppErrorHandler from "@src/appErrorHandler";

export default {
  name: "ZoneInfo",
  props: ["type"],
  components: {
    headerBox,
    zoneSearchBox,
    zoneGridBox,
    newModal
  },
  watch: {
    treeData() {
      console.log("ZoneInfo:treeData update");
    }
  },
  data() {
    return {
      treeData: [],
      treeOptions: {
        parentSelect: true,
      },
      zoneIdx: null,
      upperZoneIdx: null,
      detail: [],
      isRegist: true,
      enabled: ""
    };
  },
  created() { },
  async mounted() {
    this.treeData = [];
    await this.onSearch();
  },
  methods: {
    async getData() {
      this.treeData = [];

      // 이전 리비전과 소스 에서 아래와 같이 변경함.
      /*           
        ↓↓↓↓↓
      */
      try {
        const result = await backEndApi.zoneInfo.getZoneListHierarchi();
        const data = result.data;

        console.log(data);
        this.treeData.push(data);

      } catch (err) {
        this.treeData = [];
        new AppErrorHandler(err)
          .printErrorLog()
          .errHandling();
      }
    },
    onTreeDataSelectedIdxs(node, result) {

      if (node.parent) {
        result.unshift({ treeIdx: node.index() });
        return this.onTreeDataSelectedIdxs(node.parent, result);
      } else {
        return result;
      }
    },
    onEnabled(enabled) {
      this.enabled = enabled;
      this.$refs.grid.setItems(enabled);
    },
    async onSearch() {
      await this.getData();
    },
    async reSearch() {
      this.$refs.search.setEnabled("");
      await this.onSearch();
    },
    onNew(zoneIdx) {
      this.zoneIdx = zoneIdx;
      this.$refs.newModal.show();
    },
    onHide() {
      this.$refs.newModal.hide();
    }
  }
};
</script>

<style scoped>

</style>
