<template>
	<div>
		<vvo v-slot="v">
			<div class="row-vh d-flex flex-row ">
				<!-- LEFT -->
				<div class="w-100">
					<div class="d-flex flex-row align-items-center mt-3">
						<div class="col-4"><span class="h5">{{$t('검교정 내역')}}</span><i class="material-icons ml-1" :style="{ fontSize: '15px' }">check_circle</i></div>
						<div class="col-8">
							<valid-input
								:vid="'검교정 내역'"
								:inputType="'text'"
								:inputValue.sync="model.clbrnDescrb"
								:rules="rules.CLBRN_HISTORY_RULE"
								:errorMessage="validMessage.COMMON_BOTH_LENGTH_VALID_MESSAGE(1, 100)"
								:ref="'clbrnDescrb'"
								:isMessage="true"
							></valid-input>
							<!-- <input :ref="'clbrnDescrb'" v-model="model.clbrnDescrb" class="form-control" /> -->
						</div>
					</div>
					<div class="d-flex flex-row align-items-center mt-3">
						<div class="col-4"><span class="h5">{{$t('검교정 유형')}}</span><i class="material-icons ml-1" :style="{ fontSize: '15px' }">check_circle</i></div>
						<div class="col-8">
							<!-- <vvp :rules="'required'" :vid="'검교정 유형'" :name="'검교정 유형'"> -->
							<valid-select
								:vid="'검교정 유형'"
								:inputValue.sync="model.clbrnTypeCode"
								:rules="'required'"
								:errorMessage="popupMessages.CLBRTNHIST_NONE_DATA_TYPE_POPUP_MESSAGE"
								:selectOption="clbrnTypeCodeList"
								:setValueCode="'smallCode'"
								:setValueCodeName="'smallCodeName'"
							>
							</valid-select>
							<!-- <select class="form-control" :ref="'clbrnTypeCode'" v-model="model.clbrnTypeCode">
									<option v-for="(data, index) in clbrnTypeCodeList" :key="index" :value="data.smallCode">
										{{ data.smallCodeName }}
									</option>
								</select> -->
							<!-- </vvp> -->
						</div>
					</div>

					<div class="d-flex flex-row align-items-center mt-3">
						<div class="col-4">
							<span class="h5">{{$t('검교정 담당자')}}</span><i class="material-icons ml-1" :style="{ fontSize: '15px' }">check_circle</i>
						</div>
						<div class="col-8">
							<valid-input
								:vid="'검교정 담당자'"
								:inputType="'text'"
								:inputValue.sync="model.clbrnWorker"
								:rules="rules.CLBRN_MANAGER_RULE"
								:errorMessage="validMessage.COMMON_BOTH_LENGTH_VALID_MESSAGE(1, 20)"
								:ref="'clbrnWorker'"
								:isMessage="true"
							></valid-input>
							<!-- <input :ref="'clbrnWorker'" v-model="model.clbrnWorker" class="form-control" /> -->
						</div>
					</div>
					<div class="d-flex flex-row align-items-center mt-3">
						<div class="col-4">
							<span class="h5">{{$t('검교정 기관')}}</span>
						</div>
						<div class="col-8">
							<valid-input :vid="'검교정 기관'" :inputType="'text'" :inputValue.sync="model.clbrnOrg" :rules="rules.CLBRN_COMPANY_RULE" :NonStatus="true" ref="clbrnOrg"></valid-input>
							<!-- <input :ref="'clbrnOrg'" v-model="model.clbrnOrg" class="form-control" /> -->
						</div>
					</div>
					<div class="d-flex flex-row align-items-center mt-3">
						<div class="col-4"><span class="h5">{{$t('처리 일자')}}</span><i class="material-icons ml-1" :style="{ fontSize: '15px' }">check_circle</i></div>
						<div class="col-8">
							<vvp :rules="'required'" :vid="'처리일자'">
								<date-picker :placeholder="$t('처리 일자')" :ref="'processDate'" v-model="model.processDate" :config="{ format: 'YYYY-MM-DD', viewMode: 'days', locale: 'ko' }" />
							</vvp>
						</div>
					</div>
					<div class="d-flex flex-row align-items-center mt-3">
						<div class="col-4">
							<span class="h5">{{$t('비고')}}</span>
						</div>
						<div class="col-8">
							<valid-textarea :vid="'비고'" :inputType="'text'" :inputValue.sync="model.processDetail" :rules="rules.CLBRN_ETC_RULE" :NonStatus="true" ref="processDetail"></valid-textarea>
							<!-- <textarea :ref="'processDetail'" v-model="model.processDetail" class="form-control" /> -->
						</div>
					</div>
				</div>
			</div>
			<new-btn :isResist="isResist" @insert="onValidate(v, onRegist, model)" @update="onValidate(v, onModify, model)" @reset="onReset" @delete="onRemove" />
		</vvo>
	</div>
</template>

<script>
	import backEndApi from "../../../../api/backEndApi";
	import newBtn from "./NewBtn.vue";
	import { StatusCodes } from "http-status-codes";
	import AppErrorHandler from "@src/appErrorHandler";
	import * as rules from "@src/consts/ruleConsts.js";
	import * as validMessage from "@src/consts/validMessageConsts.js";
	import * as popupMessages from "@src/consts/popupMessageConsts";

	export default {
		components: {
			newBtn,
		},
		props: ["isResist", "serverIdx", "facilityIdx", "channelIdx", "data"],
		watch: {
			data() {
				this.model.clbrnDescrb = this.data.clbrnDescrb;
				this.model.clbrnTypeCode = this.data.clbrnTypeCode;
				this.model.clbrnWorker = this.data.clbrnWorker;
				this.model.clbrnOrg = this.data.clbrnOrg;
				this.model.processDate = this.data.processDate;
				this.model.processDetail = this.data.processDetail;
			},
		},
		data() {
			return {
				clbrnTypeCodeList: null,
				model: {
					serverIdx: this.serverIdx,
					channelIdx: this.channelIdx,
					clbrnDescrb: null,
					clbrnTypeCode: null,
					clbrnWorker: null,
					clbrnOrg: null,
					processDate: null,
					processDetail: null,
					facilityIdx: this.facilityIdx,
				},
				rules,
				validMessage,
				popupMessages,
			};
		},
		computed: {},
		created() {
			this.init();
			this.getSmallCodeList();
		},
		methods: {
			init() {
				if (this.isResist) {
					this.model.clbrnDescrb = null;
					this.model.clbrnTypeCode = null;
					this.model.clbrnWorker = null;
					this.model.clbrnOrg = null;
					this.model.processDate = null;
					this.model.processDetail = null;
				} else {
					this.getClbrnHistInfo(this.data.clbrtnIdx);
				}
			},
			getClbrnHistInfo(clbrtnIdx) {
				backEndApi.clbrtnHist.getClbrnHistInfo(clbrtnIdx).then(({ data }) => {
					this.model.clbrtnIdx = data.clbrtnIdx;
					this.model.clbrnDescrb = data.clbrnDescrb;
					this.model.clbrnTypeCode = data.clbrnTypeCode;
					this.model.clbrnWorker = data.clbrnWorker;
					this.model.clbrnOrg = data.clbrnOrg;
					this.model.processDate = data.processDate;
					this.model.processDetail = data.processDetail;
				});
			},
			getSmallCodeList() {
				backEndApi.publicCode.getSmallCodeList("clbrnTypeCode").then(({ data }) => {
					this.clbrnTypeCodeList = data;
				});
			},
			async onRegist() {
				console.log(this.model);
				// this.$emit("reSearch:param", {serverIdx : this.serverIdx, channelIdx : this.channelIdx,});

				let result = await this.alertQuestion(popupMessages.COMMON_SAVE_CONFIRM_POPUP_MESSAGE);
				if (!result.value) return;

				try {
					let res = await backEndApi.clbrtnHist.insertClbrn(this.model);
					console.log(res.data);

					this.alertNoti(popupMessages.COMMON_SAVE_POPUP_MESSAGE);

					this.$emit("reSearch:param", { serverIdx: this.serverIdx, channelIdx: this.channelIdx, facilityIdx: this.facilityIdx });
				} catch (err) {
					new AppErrorHandler(err)
						.setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger)
						.createApiHandle(StatusCodes.INTERNAL_SERVER_ERROR)
						.errHandling(true);
				}
			},
			async onModify() {
				console.log("New modal : onModify");
				// this.$emit("reSearch:param", {serverIdx : this.serverIdx, channelIdx : this.channelIdx,});
				let result = await this.alertQuestion(popupMessages.COMMON_UPDATE_CONFIRM_POPUP_MESSAGE);
				if (!result.value) return;

				try {
					let res = await backEndApi.clbrtnHist.updateClbrn(this.model);

					console.log(res.data);
					this.alertNoti(popupMessages.COMMON_UPDATE_POPUP_MESSAGE);

					this.$emit("reSearch:param", { serverIdx: this.serverIdx, channelIdx: this.channelIdx, facilityIdx: this.facilityIdx });
				} catch (err) {
					new AppErrorHandler(err)
						.setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger)
						.createApiHandle(StatusCodes.INTERNAL_SERVER_ERROR)
						.errHandling(true);
				}
			},
			onRemove() {
				this.alertConfirmWarning(popupMessages.COMMON_DELETE_CONFIRM_POPUP_MESSAGE).then((result) => {
					if (!result.value) return;

					backEndApi.clbrtnHist.deleteClbrn(this.model.clbrtnIdx).then(({ data }) => {
						console.log(data);

						this.alertNoti(popupMessages.COMMON_DELETE_POPUP_MESSAGE);
						this.$emit("reSearch:param", { serverIdx: this.serverIdx, channelIdx: this.channelIdx, facilityIdx: this.facilityIdx });
					});
				});
			},
			// isValidation() {
			// 	var flag = true;
			//   console.log(this.$refs);
			// 	if (!this.$refs.clbrnDescrb.value) {
			// 		this.$refs.clbrnDescrb.focus();
			// 		this.alertNoti(popupMessages.CLBRTNHIST_REGIST_DES_VALID_POPUP_MESSAGE);
			// 		flag = false;
			// 	} else if (!this.$refs.clbrnTypeCode.value) {
			// 		this.$refs.ptName.focus();
			// 		this.alertNoti(popupMessages.CLBRTNHIST_POINT_TYPE_VALID_POPUP_MESSAGE); // 팝업 메세지 안나옴
			// 		flag = false;
			// 	} else if (!this.$refs.processDate.value) {
			// 		this.$refs.processDate.focus();
			// 		this.alertNoti(popupMessages.CLBRTNHIST_PROCESSING_DATE_VALID_POPUP_MESSAGE); // 팝업 메세지 안나옴
			// 		flag = false;
			// 	}
			// 	return flag;
			// },
			onReset() {
				this.model.clbrnDescrb = null;
				this.model.clbrnTypeCode = null;
				this.model.clbrnWorker = null;
				this.model.clbrnOrg = null;
				this.model.processDate = null;
				this.model.processDetail = null;
			},
		},
	};
</script>
