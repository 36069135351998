<template>  
  <div class="mt-5 text-center">
    <button type="button" class="ml-3 btn btn-light" @click="onSave()" :disabled="disabled">{{$t('저장')}}</button>
    <button type="button" class="ml-3 btn btn-light" @click="onRemove()" v-if="!isResist">{{$t('삭제')}}</button>
    <button type="button" class="ml-3 btn btn-light" @click="onReset()">{{$t('초기화')}}</button>
  </div>
</template>

<script>

export default {
  components: {},
  props: ["isResist", "disabled"],
  watch: {},
  data() {
    return {
    };
  },
  computed: {
    
  },
  created() {
  },
  methods: {
    // 저장 버튼 
    onSave() {
      if(this.isResist){
        this.$emit("insert");
      }else{
        this.$emit("update");
      }
    },
    onReset(){
      this.$emit("reset");
    },
    onRemove(){
      this.$emit("delete");
    }
  },
};
</script>
