<template>
  <!-- 에너지원 -->
  <div class="font-weight-bold d-flex flex-row" :style="{ fontSize: '15px' }">
    <div class="p-r-5">
      <select class="form-control" placeholder="요금구분 선택" ref="costDvsnCode" v-model="costDvsnCode">
        <!-- <option value="">선택</option> -->
        <option v-for="(code, index) in costDvsnCodeList" :key="index" :value="code.costDvsnCode">
          {{ code.costDvsnCodeName }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
import backEndApi from "@api/backEndApi.js";
import AppErrorHandler from "@src/appErrorHandler";

export default {
  components: {},
  props: [],
  watch: {},
  data() {
    return {
      costDvsnCode: null,
      costDvsnCodeList: null
    };
  },
  computed: {

  },
  created() {
  },
  mounted() {
  },
  updated() {
  },
  destroyed() {
  },
  methods: {
    async getCostDvsnCodeList(costBasicCode) {
      if (costBasicCode) {

        // 이전 리비전과 소스 에서 아래와 같이 변경함.
        /*           
          ↓↓↓↓↓
        */
        try {
          const result = await backEndApi.cost.getDvsnList(costBasicCode);
          this.costDvsnCodeList = result.data;
          this.costDvsnCode = result.data[0].costDvsnCode;

        } catch (err) {

          this.costDvsnCodeList = null;
          this.costDvsnCode = null;

          new AppErrorHandler(err)
            .printErrorLog()            
            .errHandling();            
        }

      } else {
        this.costDvsnCodeList = null;
        this.costDvsnCode = null;
      }
    },
    getValue() {
      return this.costDvsnCode;
    },
    focus() {
      this.$refs.costDvsnCode.focus();
    }
  },
};
</script>

<style scoped></style>
