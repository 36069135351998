<template>
  <b-table 
  hover
  bordered
  head-variant="dark"
  no-border-collapse
  show-empty
  sticky-header
  ref="selectableTable"
  :class="'text-center pulibcTableClass'"
  :items="items"
  :fields="fields"
  :empty-text="$t('조회된 결과가 없습니다.')"
  
  > 
  <template #empty="scope">
    <h4>{{ scope.emptyText }}</h4>
  </template>
  
  <!-- <template #cell(selected)="{rowSelected}">
    <template v-if="rowSelected">
      <span aria-hidden="true">&check;</span>
      <span class="sr-only">Selected</span>
    </template>
    <template v-else>
      <span aria-hidden="true">&nbsp;</span>
      <span class="sr-only">Not selected</span>
    </template>
  </template> -->
  
  <!-- <template #cell(index)="data">
    {{ data.index + 1 }}
  </template> -->
  
  
  <!-- <template #cell(smallCodeName)="row">
    <b-form-input v-model="row.item.smallCodeName" @change="onRowEnabledChange(row)" :style="{minWidth: '150px'}"></b-form-input>
  </template>
  
  <template #cell(smallCode)="row">
    <b-form-input v-model="row.item.smallCode" @change="onRowEnabledChange(row)" :disabled="row.item.isNew === true ? false : true" :style="{minWidth: '120px'}"></b-form-input>
  </template> -->
  
  <template #cell(etcInfo2)="row">
    <select class="form-control" ref="enabled" v-model="row.item.etcInfo2" @change="onRowEnabledChange(row)" :style="{minWidth: '100px'}">
      <option v-for="(data, i) in enabledCodeList" :key="`enabledCode${i}`" :value="data.smallCode">
        {{ data.smallCodeName }}
      </option>
    </select>
  </template>
  
</b-table>
</template>

<style>
.b-table-sticky-header {
  max-height: 760px;
}

.pulibcTableClass table tbody tr td{
  vertical-align: middle;
}
</style>

<script>
import backEndApi from '@src/api/backEndApi';


export default {
  components: {
    
    // detailCard,,detailBtn
  },
  props: ["data"],
  data() {
    return {
      fields : null,
      items : [],
      emptyText : null,
      enabledCodeList: [],
      selectedRow: [],
      newRowList: [],
      changedRow: [],
      checkboxList: [],
      originData: [],
      saveItems: [],
    };
  },
  watch: {
    data(){
      // this.selectedRow._showDetails =  !this.selectedRow._showDetails;
      this.setItems();
    },
  },
  created() {
    this.init();
    this.setFields();
    this.setItems();
  },
  mounted(){
    
  },
  methods: {
    init(){
      this.getSmallCodeList();
    },
    onRowEnabledChange(row){
      // console.log(row, a, b, c);
      
      const compare = this.originData.find(data => data.smallCode === row.item.smallCode);
      
      let isDifferent = false;
      
      for(let i =0; i < Object.keys(compare).length; i++){
        const keyName = Object.keys(compare)[i];
        if(compare[keyName] !== row.item[keyName]){
          isDifferent = true;
          break;
        }
      }
      
      let index = this.changedRow.findIndex(data => data.smallCode === row.item.smallCode);
      if(index === -1){
        if(isDifferent === true) this.changedRow.push(row.item);
      } else {
        if(isDifferent === true) this.changedRow[index] = row.item;
        else this.changedRow.splice(index, 1);
      }
      
      this.saveItems = this.changedRow
      
      this.$emit("getSaveItems", this.saveItems);

      console.log(this.saveItems);
    },
    
    async getSmallCodeList(){
      const result = await backEndApi.publicCode.getSmallCodeList('enabled');
      
      this.enabledCodeList = result.data;
    },
    clearSeclected(){
      this.$refs.selectableTable.clearSelected()
    },
    setFields(){
      this.fields = [
      { key: 'smallCode', label: global.xe.$t("코드")},
      { key: 'smallCodeName', label: global.xe.$t("코드명")},
      { key: 'etcInfo2',  label: global.xe.$t("사용여부") },
      ]
    },
    setItems(){
      if(this.data === null) return;
      
      this.items = this.data;
      this.originData = JSON.parse(JSON.stringify(this.items));
      
      for(let i = 0; i < this.items.length; i++){
        this.checkboxList.push(false);
      }
    },
    onModify(){
      this.$refs.detail.onModify();
    },
    onRemove(){
      this.$refs.detail.onRemove();
    },
    onReset(){
      this.$refs.detail.onReset();
    },
    reSearch(){
      this.$emit("reSearch");
    }
  },
};
</script>
