<template>
  <div v-if="type=='zone'" class="ml-2">
    <select class="form-control" ref="enabled" @change="onChangeEnabled()">
      <option value="">{{$t('전체')}}</option>
      <option value="Y">{{$t('사용')}}</option>
    </select>
  </div>

  <div v-else-if="type=='code'" class="ml-2">
    <select class="form-control" ref="code" @change="onChangeCode()">
      <option v-for="(code, index) in codes"
        :key="index"
        :value="code.largeCode">
      {{ code.largeCodeName }}
      </option>
    </select>
  </div>
</template>

<script>
import backEndApi from "../../../../api/backEndApi";

export default {
  components: {},
  props: ["type"],
  watch: {},
  data() {
    return {
      codes : this.getCodes()
    };
  },
  computed: {
    
  },
  created() {
    console.log("ManagementCombo");
  },
  methods: {
    onChangeEnabled(){
      this.$emit("search:enabled",this.$refs.enabled.value);
    },
    onChangeCode(){
      this.$emit("search:code",this.$refs.code.value);
    },
    async getCodes() {
      
      // 이전 리비전과 소스 에서 아래와 같이 변경함.
			/*           
				↓↓↓↓↓
			*/
      try {
        const result = await backEndApi.publicCode.getOprtnCodeList();
        console.log(result.data);
        this.codes = result.data;
      } catch {
      }
    },
    setCodes(code){
      this.$refs.code.value = code;
      this.onChangeCode();
    },
    setEnabled(enabled){
      this.$refs.enabled.value = enabled;
    },
    getEnabledValue(){
      return this.$refs.enabled.value;
    }
  },
};
</script>
