<template>
	<b-table
		hover
		no-border-collapse
		bordered
		head-variant="light"
		show-empty
		sticky-header
		ref="selectableDetailTable"
		:class="'text-center'"
		:items="items"
		:fields="fields"
		:empty-text="$t('조회된 결과가 없습니다.')"
		:select-mode="'single'"
		selectable
		@row-selected="onRowSelected"
	>
		<template #empty="scope">
			<h4>{{ scope.emptyText }}</h4>
		</template>

		<template #cell(selected)="{ rowSelected }">
			<template v-if="rowSelected">
				<span>&check;</span>
				<span class="sr-only">Selected</span>
			</template>
			<template v-else>
				<span>&nbsp;</span>
				<span class="sr-only">Not selected</span>
			</template>
		</template>

		<template #cell(index)="data">
			{{ data.index + 1 }}
		</template>
	</b-table>
</template>

<style scoped>
	.b-table-sticky-header {
		max-height: 760px;
	}
</style>

<script>
	export default {
		components: {},
		props: ["data"],
		data() {
			return {
				fields: null,
				items: [],
				emptyText: null,
				selectedRow: [],
			};
		},
		watch: {
			data() {
				this.selectedRow._showDetails = !this.selectedRow._showDetails;
				this.setItems();
			},
		},
		created() {
			this.setFields();
			this.setItems();
		},
		mounted() {},
		methods: {
			onRowSelected(item) {
				if (item.length > 0) {
					this.$emit("modify:item", item[0]);
				}
			},
			clearSeclected() {
				this.$refs.selectableDetailTable.clearSelected();
			},
			setFields() {
				this.fields = [
					{ key: "index", label: global.xe.$t("순번") },
					{ key: "clbrnDescrb", label: global.xe.$t("검교정 내용") },
					{ key: "clbrnTypeCodeName", label: global.xe.$t("진행 항목") },
					{ key: "clbrnWorker", label: global.xe.$t("처리 담당자") },
					{ key: "clbrnOrg", label: global.xe.$t("검교정 진행 기관") },
					{ key: "processDate", label: global.xe.$t("처리 일자") },
				];
			},
			setItems() {
				if (this.data) {
					this.items = this.data.map((data) => {
						return { ...data, _showDetails: false };
					});
				}
			},
		},
	};
</script>
