<template>
	<vvo v-slot="v">
	<div :style="'ml-1 max-height:684px; min-height:300px; min-width:100%;'">
			<div class="row align-items-center mt-3">
				<div class="col-xl-4 text-left">
					<h5>{{$t('상위 구역')}}</h5>
				</div>
				<div class="col-xl-8 text-left">
					<tree-select
						disabled
						v-model="model.upperZoneIdx"
						:ref="'upperZoneIdx'"
						:load-options="loadOptions"						
						:placeholder="controlMessages.ZONE_TREE_PLACEHOLDER_MESSAGE"
						:noChildrenText="controlMessages.ZONE_TREE_NOCHILDREN_MESSAGE"
						:noResultsText="$t(controlMessages.COMMON_TREE_NOSEARCH_MESSAGE)"						
						:multiple="false"
						:options="options" />
				</div>
			</div>
			<div class="row align-items-center mt-3">
				<div class="col-xl-4 text-left">
					<h5>{{$t('구역명')}}</h5>
				</div>
				<div class="col-xl-8 text-left">
					<valid-input :vid="'구역명'" :inputType="'text'" :inputValue.sync="model.zoneName" :rules="rules.ZONE_NAME_RULE" :errorMessage="validMessage.COMMON_BOTH_LENGTH_VALID_MESSAGE(1, 30)"></valid-input>

					<!-- <input :ref="'zoneName'" class="form-control" v-model="model.zoneName" /> -->
				</div>
			</div>
			<div class="row align-items-center mt-3">
				<div class="col-xl-4 text-left">
					<h5>{{$t('구역 구분')}}</h5>
				</div>
				<div class="col-xl-8 text-left">
					<select class="form-control" :ref="'zoneDvsnCode'" v-model="model.zoneDvsnCode">
						<option v-for="(code, index) in zoneDvsnCodeList" :key="index" :value="code.smallCode">
							{{ code.smallCodeName }}
						</option>
					</select>
				</div>
			</div>
			<div class="row align-items-center mt-3">
				<div class="col-xl-4 text-left">
					<h5>{{$t('구역 상세')}}</h5>
				</div>
				<div class="col-xl-8 text-left">
					<valid-input :inputType="'text'" :inputValue.sync="model.zoneDetailDsc" :rules="rules.ZONE_DETAIL_RULE" :NonStatus="true"></valid-input>

					<!-- <input :ref="'zoneDetailDsc'" class="form-control" v-model="model.zoneDetailDsc" /> -->
				</div>
			</div>
			<!-- <div class="row align-items-center mt-3">
      <div class="col-xl-4 text-left">
        <h5>도면파일</h5>
      </div>
      <div class="col-xl-8 text-left">
        <input type="file" id="file" ref="file" v-on:change="handleFileUpload()"/>
      </div>
    </div> -->
			<div class="row align-items-center mt-3">
				<div class="col-xl-4 text-left">
					<h5>{{$t('사용여부')}}</h5>
				</div>
				<div class="col-xl-8 text-left">
					<select class="form-control" :ref="'enabled'" v-model="model.enabled">
						<option value="Y">{{$t('사용')}}</option>
						<option value="N">{{$t('사용안함')}}</option>
					</select>
				</div>
			</div>
			<button-box @insert="onValidate(v, onSave)"/>
	</div>
	</vvo>
</template>

<script>
import backEndApi from "../../../../api/backEndApi";
import buttonBox from "./NewZoneInfoBtn.vue";
import * as rules from "@src/consts/ruleConsts.js";
import * as validMessage from "@src/consts/validMessageConsts.js";
import * as popupMessages from "@src/consts/popupMessageConsts";
import * as controlMessages from "@src/consts/controlMessageConsts";
import AppErrorHandler from "@src/appErrorHandler";

export default {
	components: {
		buttonBox,
	},
	props: ["zoneIdx"],
	watch: {},
	data() {
		return {
			model: {
				enabled: null,
				upperZoneIdx: this.zoneIdx,
				zoneDetailDsc: null,
				zoneDvsnCode: null,
				zoneIdx: null,
				zoneName: null,
			},
			codes: null,
			zoneInfo: null,
			zoneDvsnCodeList: null,
			options: null,

			dsnFile: null,
			rules,
			validMessage,
			popupMessages,
			controlMessages
		};
	},
	computed: {},
	async created() {
		await this.getZoneListHierarchi();
		await this.getZoneDvsnCode();
	},
	methods: {
		async getZoneListHierarchi() {

			// 이전 리비전과 소스 에서 아래와 같이 변경함.
			/*           
				↓↓↓↓↓
			*/
			try {
				const result = await backEndApi.zoneInfo.getZoneListHierarchi();
				const data = result.data;

				this.options = [];

				let temp = [];
				temp.push(data);

				this.options = this.setZoneTreeOption(temp);
				this.zoneInfo = temp;
			} catch (err) {
				new AppErrorHandler(err)
				.printErrorLog()
				.errHandling();
			}
		},
		setZoneTreeOption(resultData) {
			resultData.forEach((data) => {
				data.id = data.zoneIdx;
				data.label = data.zoneName;

				if (data["children"]) {
					return this.setZoneTreeOption(data["children"]);
				} else {
					return data;
				}
			});

			return resultData;
		},
		async getZoneDvsnCode() {

			// 이전 리비전과 소스 에서 아래와 같이 변경함.
			/*           
				↓↓↓↓↓
			*/
			try {
				const result = await backEndApi.publicCode.getSmallCodeList("zoneDvsnCode");
				this.zoneDvsnCodeList = result.data;
			} catch (err) {
				new AppErrorHandler(err)
					.printErrorLog()
					.errHandling();
			}
		},
		async onSave() {
			console.log(this.model);

			if (this.isValidation()) {
				let upperZoneIdxName = null;

				// 이전 리비전과 소스 에서 아래와 같이 변경함.
				/*           
					↓↓↓↓↓
				*/
				try {
					const result = await backEndApi.zoneInfo.getZoneInfo(this.model.upperZoneIdx);
					upperZoneIdxName = result.data.zoneName;
					const popupResult = await this.alertQuestion(this.model.zoneName + "을(를) " + upperZoneIdxName + popupMessages.ZONE_ADD_POPUP_MESSAGE);
					if (!popupResult.value)
						return;


					try {
						await backEndApi.zoneInfo.insertZoneInfo(this.model);
						this.alertNoti(popupMessages.COMMON_SAVE_POPUP_MESSAGE);
						this.$emit("hide");
						this.$emit("reSearch");
					} catch (err) {
						new AppErrorHandler(err)
							.setOtherMessage(popupMessages.COMMON_SAVE_ERROR_POPUP_MESSAGE, this.alertDanger)
							.errHandling();
					}
				} catch (err) {
					new AppErrorHandler(err)
						.printErrorLog()
						.errHandling();
				}
			}
		},
		isValidation() {
			var flag = true;
			if (!this.model.zoneDvsnCode) {
				this.alertWarning(popupMessages.ZONE_UPDATE_DIV_VALID_POPUP_MESSAGE);
				flag = false;
			} else if (!this.model.enabled) {
				this.alertWarning(popupMessages.COMMON_USAGE_VALID_POPUP_MESSAGE);
				flag = false;
			}
			return flag;
		},
		async matchUpperZoneIdxName(upperZoneIdx) {
			let name = null;

			// 이전 리비전과 소스 에서 아래와 같이 변경함.
			/*           
				↓↓↓↓↓
			*/
			try {
				const result = await backEndApi.zoneInfo.getZoneInfo(upperZoneIdx);
				name = result.data.zoneName;
			} catch (err) {
				new AppErrorHandler(err)
					.printErrorLog()
					.errHandling();
			}
			return name;
		},
		loadOptions({ callback }) {
			callback();
		},
		handleFileUpload() {
			this.dsnFile = this.$refs.file.files[0];
		},
	},
};
</script>
