<template>
    <!-- 휴일구분 -->
    <div class="font-weight-bold d-flex flex-row" :style="{ fontSize: '15px'}">
      <div class="pt-2 p-l-5">
        <span>{{$t('휴일 구분')}}</span>
      </div>
      <div class="p-l-5 p-r-5">
        <select class="form-control" ref="holiTypeCode" v-model="holiTypeCode">
          <option value="">{{$t('선택')}}</option>
          <option v-for="(code, index) in smallCodeList" :key="index" :value="code.smallCode">
            {{ code.smallCodeName }}
          </option>
        </select>
      </div>
    </div>
</template>

<script>
import backEndApi from "@api/backEndApi.js";

export default {
  components: {},
  props: [],
  watch: {},
  data() {
    return {
      holiTypeCode : "",
      smallCodeList : null
    };
  },
  computed: {
    
  },
  created() {
  },
  async mounted(){
    await this.getSmallCodeList();
  },
  updated(){
  },
  destroyed(){
  },
  methods: {
    async getSmallCodeList(){
      
      // 이전 리비전과 소스 에서 아래와 같이 변경함.
			/*           
				↓↓↓↓↓
			*/
      const result = await backEndApi.publicCode.getSmallCodeList("holiTypeCode");
      this.smallCodeList = result.data;
      this.$emit("usageCostItemCombo:energySourceCode",this.holiTypeCode);

    },
    async setInitCode(){
      
      // 이전 리비전과 소스 에서 아래와 같이 변경함.
			/*           
				↓↓↓↓↓
			*/
      try {
        const result = await backEndApi.publicCode.getSmallCodeList("holiTypeCode");
        return result.data[0].smallCode;
        
      } catch {
        return null;
      }
    },
    getValue(){
      return this.holiTypeCode;
    },
    focus(){
      this.$refs.holiTypeCode.focus();
    }
  },
};
</script>

<style scoped>

</style>
