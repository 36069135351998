<template>
	<b-container>
		<vvo v-slot="v">
			<b-form class="mb-2" inline>
				<div class="d-flex flex-row w-100" v-bind:style="{ fonSize: '15px' }">
					<div class="mt-auto mb-auto w-75">
						<i class="material-icons align-middle m-b-2">info</i>
						<strong class="p-l-1">{{$t('요금계산 관제점 설정')}}</strong>
						<a href="#" v-show="isGridBoxRightFlag" @click="update()"><i class="material-icons align-middle m-b-auto m-t-auto" :style="{ fontSize: '16px' }">update</i></a>
					</div>
					<b-button v-show="isBtnFlag && isGridBoxRightFlag" ref="pushBtn" class="ml-auto w-25" variant="primary" @click="onPush()">{{$t('추가')}}</b-button>
					<b-button v-show="!isBtnFlag" ref="popBtn" class="ml-auto w-25" variant="danger" @click="onPop()">{{$t('제거')}}</b-button>
				</div>
			</b-form>
			<b-table
				hover
				outlined
				no-border-collapse
				head-variant="light"
				show-empty
				sticky-header="350px"
				ref="costPointMapTable"
				:style="'min-width:100%; z-index:0;'"
				:class="'text-center mb-0'"
				:items="items"
				:fields="fields"
				:empty-text="$t('조회된 결과가 없습니다.')"
				:select-mode="'single'"
				selectable
				:details-td-class="'rowDetailStyle'"
				@row-selected="onRowSelected"
				@row-clicked="onRowDetail"
			>
				<template #empty="">
					<h4>{{ "" }}</h4>
				</template>

				<template #cell(contCapacity)="row"> {{ row.item.contCapacity }} kW </template>

				<template #cell(selected)="{ rowSelected }">
					<template v-if="rowSelected">
						<span>&check;</span>
						<span class="sr-only">Selected</span>
					</template>
					<template v-else>
						<span>&nbsp;</span>
						<span class="sr-only">Not selected</span>
					</template>
				</template>

				<template #cell(index)="data">
					{{ data.index + 1 }}
				</template>

				<template #cell(delete)="row">
					<a href="#" @click="onRemove(row.item.ptAddr, row.index)"><i class="material-icons align-middle m-b-2">delete</i></a>
				</template>

				<template #row-details="row" :class="'bg-white'">
					<b-card>
						<b-form class="mb-2" inline>
							<div class="d-flex flex-row w-100" v-bind:style="{ fonSize: '15px' }">
								<div class="h5 text-left mt-auto mb-auto w-50">
									<strong v-show="isRegist" class="p-l-1">{{$t('요금계산 관제점 등록')}}</strong>
									<strong v-show="!isRegist" class="p-l-1">{{$t('요금계산 관제점 조회')}}</strong>
								</div>
								<div class="text-right ml-auto w-10">
									<b-button v-show="isRegist" variant="primary" @click="onRegist(row)">{{$t('저장')}}</b-button>
									<b-button v-show="!isRegist" variant="primary" @click="onModify(row)">{{$t('저장')}}</b-button>
								</div>
							</div>
						</b-form>
						<div class="d-flex align-items-center mt-5">
							<div class="col-5 text-left">
								<span class="h6 mb-0">{{$t('요금계산 관제점')}}</span>
							</div>
							<div class="col-7">
								<select class="form-control" :placeholder="$t('요금계산 관제점')" ref="ptAddr" v-model="row.item.ptAddr">
									<option value="">{{$t('선택')}}</option>
									<option v-for="(code, index) in targetPointList" :key="index" :value="code.ptAddr">
										{{ code.ptName }}
									</option>
								</select>
							</div>
						</div>
						<div class="d-flex align-items-center mt-3">
							<div class="col-5 text-left">
								<span class="h6 mb-0">{{$t('에너지 공급 유형')}}</span>
							</div>
							<div class="col-7">
								<select class="form-control" :placeholder="$t('에너지 공급 유형')" ref="energySplyTypeCode" v-model="row.item.energySplyTypeCode">
									<option :value="null">{{$t('선택')}}</option>
									<option v-for="(code, index) in energySplyTypeCodeList" :key="index" :value="code.smallCode">
										{{ code.smallCodeName }}
									</option>
								</select>
							</div>
						</div>
						<div class="d-flex align-items-center mt-3">
							<div class="flex-row col-5 text-left">
								<span class="h6 mb-0">{{$t('계약 전력')}}</span>
							</div>
							<div class="col-5">
								<valid-input
                  :vid="'계약전력'"
									:inputType="'number'"
									:inputValue.sync="row.item.contCapacity"
									:rules="rules.COST_CONTRACT_ELEC"
									:classList="'text-right'"
									:isMessage="true"
									ref="contCapacity"
								></valid-input>
							</div>
							<div class="col-2">
								{{ "kW" }}
							</div>
						</div>
					</b-card>
				</template>
			</b-table>
		</vvo>
	</b-container>
</template>

<script>
	import backEndApi from "../../../../../api/backEndApi";
	import AppErrorHandler from "@src/appErrorHandler";
	import * as validMessage from "@src/consts/validMessageConsts.js";
	import * as rules from "@src/consts/ruleConsts.js";
	import * as popupMessages from "@src/consts/popupMessageConsts";

	export default {
		components: {},
		props: ["isGridBoxRightFlag"],
		watch: {
			data() {
				this.setFields();
				this.setItems();
			},
			isGridBoxRightFlag() {
				this.resetItmes();
			},
		},
		data() {
			return {
				fields: null,
				items: [],
				emptyText: global.xe.$t("조회자료 없음"),
				selected: [],
				selectedRow: [],
				isBtnFlag: true,
				isRegist: false,
				data: null,
				targetPointList: null,
				energySplyTypeCode: null,
				energySplyTypeCodeList: null,
				energySourceCode: null,
				costBasicCode: null,
				costDvsnCode: null,
				rules,
				validMessage,
				popupMessages,
			};
		},
		computed: {},
		async created() {
			this.setFields();
			this.setItems();
			await this.getTargetPointList();
			await this.getEnergySplyTypeCode();
		},
		methods: {
			onRowSelected(item) {
				if (!item.length > 0) {
					this.selectRowFalse();
				}
			},
			onRowDetail(row) {
				// 이전 선택된 row close
				this.selectedRow._showDetails = !this.selectedRow._showDetails;

				// // 선택된 row open
				row._showDetails = !row._showDetails;

				if (row._showDetails) {
					this.isRegistFlase();
				} else {
					this.isRegistTrue();
				}

				// 선택된 row 저장
				this.selectedRow = row;
			},
			clearSeclected() {
				this.$refs.costPointMapTable.clearSelected();
			},
			async getTargetPointList() {
				// 이전 리비전과 소스 에서 아래와 같이 변경함.
				/*           
				↓↓↓↓↓
			*/
				try {
					const result = await backEndApi.cost.getTargetPointList();
					if (result.data.length > 0) {
						this.targetPointList = result.data;
					} else {
						this.targetPointList = null;
					}
				} catch (err) {
					new AppErrorHandler(err).printErrorLog().errHandling();
				}
			},

			async getEnergySplyTypeCode() {
				this.energySplyTypeCodeList = await backEndApi.publicCode.getOprtnSmallCodeList("energySplyTypeCode", null, "Y");
				this.energySplyTypeCodeList = this.energySplyTypeCodeList.data;
			},

			setCostInfo(data) {
				console.log(data);
				this.energySourceCode = data.energySourceCode;
				this.costDvsnCode = data.costDvsnCode;
				this.costBasicCode = data.costBasicCode;
				this.energySplyTypeCode = data.energySplyTypeCode;
			},
			setData(resultData) {
				this.data = resultData;
			},
			setFields() {
				this.fields = [
					{ key: "ptName", label: global.xe.$t("적용 항목명"), thClass: "w-50" },
					{ key: "contCapacity", label: global.xe.$t("계약 전력"), thClass: "w-50" },
					{ key: "delete", label: "", thClass: "w-25" },
				];
			},
			setItems() {
				this.items = this.data;
			},
			onPush() {
				let item = {
					ptAddr: "",
					ptName: "",
					energySplyTypeCode: null,
					contCapacity: 0,
					_showDetails: true,
				};

				this.items.push(item);
				// this.clearSeclected();
				this.selectRowFalse();
				// this.onRowDetail(item);

				this.isRegistTrue();
				this.isBtnFlagFalse();
			},
			onPop() {
				this.items.pop();
				this.isRegistFlase();
				this.isBtnFlagTrue();
			},
			async onRegist(row) {
				console.log(row);

				// 이전 리비전과 소스 에서 아래와 같이 변경함.
				/*           
				↓↓↓↓↓
			*/

				if (this.isEmpty(this.$refs.ptAddr.value)) {
					this.alertDanger(popupMessages.COST_TABLE_POINT_ADDR_REQUIRED_POPUP_MESSAGE);
					return;
				}

				let param = {
					ptAddr: this.$refs.ptAddr.value,
					energySourceCode: this.energySourceCode,
					energySplyTypeCode: this.$refs.energySplyTypeCode.value,
					costBasicCode: this.costBasicCode,
					costDvsnCode: this.costDvsnCode,
					contCapacity: this.$refs.contCapacity.value,
				};

				try {
					await backEndApi.cost.checkDuplCostMap(this.$refs.ptAddr.value);
					// 등록 가능
				} catch (err) {
					this.onPop();
					new AppErrorHandler(err)
          .setOtherMessage(popupMessages.COST_APPLY_POINT_REGIST_DUP_ERROR_POPUP_MESSAGE, this.alertNoti)
          .createBizHandle(this.ERR_KIND.DUPLICATE_DATA, this.popupMessages.COST_APPLY_POINT_REGIST_DUP_ERROR_POPUP_MESSAGE, this.alertDanger())
          .errHandling();
          return;
				}

				try {
					await backEndApi.cost.insertCostMap(param);
					this.alertNoti(popupMessages.COST_APPLY_POINT_REGIST_POPUP_MESSAGE);
					this.onRowDetail(row.item);
					this.selectRowFalse();
					this.isBtnFlagTrue();

					this.$emit("getCostMapList");
				} catch (err) {
					new AppErrorHandler(err)
						.setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger)
						.printErrorLog()
						.errHandling();
				}
			},
			async onModify(row) {
				console.log(row);

				let param = {
					ptAddr: this.$refs.ptAddr.value,
					energySourceCode: this.energySourceCode,
					energySplyTypeCode: this.$refs.energySplyTypeCode.value,
					costBasicCode: this.costBasicCode,
					costDvsnCode: this.costDvsnCode,
					contCapacity: this.$refs.contCapacity.value,
				};
				console.log(param);

				// 이전 리비전과 소스 에서 아래와 같이 변경함.
				/*           
				↓↓↓↓↓
			*/
				try {
					await backEndApi.cost.updateCostMap(param);
					this.alertNoti(popupMessages.COST_APPLY_POINT_UPDATE_SUCCESS_POPUP_MESSAGE);

					this.onRowDetail(row.item);
					this.selectRowFalse();
					this.isBtnFlagTrue();

					this.$emit("getCostMapList");
				} catch (err) {
					new AppErrorHandler(err)
						.setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger)
						.printErrorLog()
						.errHandling();
				}

				this.clearSeclected();
			},
			async onRemove(ptAddr, index) {
				ptAddr, index;

				// 이전 리비전과 소스 에서 아래와 같이 변경함.
				/*           
				↓↓↓↓↓
			*/
				const result = await this.alertConfirmWarning(`${popupMessages.COMMON_DELETE_CONFIRM_POPUP_MESSAGE}`);

				if (!result.value) return;

				try {
					await backEndApi.cost.deleteCostMap(ptAddr);
					this.alertNoti(popupMessages.COST_APPLY_POINT_DELETE_SUCCESS_POPUP_MESSAGE);
					this.$emit("getCostMapList");
				} catch (err) {
					new AppErrorHandler(err)
						.setOtherMessage(popupMessages.COMMON_REMOVE_ERROR_POPUP_MESSAGE, this.alertDanger)
						.printErrorLog()
						.errHandling();
				}
			},
			update() {
				this.isBtnFlagTrue();
				this.$emit("getCostMapList");
			},
			selectRowFalse() {
				this.selectedRow._showDetails = false;
				this.selectedRow = [];
			},
			resetItmes() {
				this.items = [];
			},
			isBtnFlagTrue() {
				this.isBtnFlag = true;
			},
			isBtnFlagFalse() {
				this.isBtnFlag = false;
			},
			isRegistTrue() {
				this.isRegist = true;
			},
			isRegistFlase() {
				this.isRegist = false;
			},
		},
	};
</script>
