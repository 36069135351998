<template>
  <div class="col-md-12 default">
    <!-- 운영관리 -->
    <code-info v-if="type=='code'" :type="type"/>

    <!-- 구역관리 -->
    <zone-info v-else-if="type=='zone'" :type="type"/>

    <!-- 검교정관리 -->
    <clbrtn-hist-info v-else-if="type=='clbrtnHist'" :type="type"/>

    <!-- 요금제정보관리 -->
    <cost-info v-else-if="type=='cost'" :type="type"/>

    <!-- 휴일관리 -->
    <holiday-info v-else-if="type=='holiday'" :type="type"/>

   
  </div>
</template>

<script>
import zoneInfo from "./zone/ZoneInfo.vue";

import codeInfo from "./code/CodeInfo.vue";

import clbrtnHistInfo from "./clbrtn/ClbrtnHist.vue";

import costInfo from "./cost/CostInfo.vue";

import holidayInfo from "./holiday/HolidayInfo.vue";

export default {
  name: "Management",
  components: {
    zoneInfo,
    codeInfo,
    clbrtnHistInfo,
    costInfo,
    holidayInfo,
  },
  props: ["type"],
  data() {
    return {
      
    };
  },
  computed: {},
  created() {
  },
  mounted() {
  },

  methods: {
   
  },
};
</script>
