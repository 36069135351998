<template>
  <div>
    <div class="font-weight-bold d-flex flex-row m-t-20">
      <div class="pr-5" :style="{ width: '70%'}">
        <p class="text-right text-danger h6">※ {{$t('휴일구분이 법정 공휴일이면 에너지 사용비용에 영향이 미칩니다. 등록 시 주의 하시기 바랍니다.')}} </p>
        <holiday-table :ref="'holidayTable'"
          @reSearch="reSearch"
        />
        
      </div>
      <div class="pr-5" :style="{ width: '15%'}" />
    </div>
  </div>
</template>

<script>
import "element-ui/lib/theme-chalk/index.css";
import holidayTable from "../../component/gridBox/management/holiday/HolidayTable.vue";

export default {
  props: [],
  components: { 
    holidayTable,
  },
  watch: {
    
  },
  data() {
    return {
      
    };
  },
  created() {
  },
  mounted() {
    
  },
  methods: {
    submit(data){
      this.$refs.holidayTable.setItems(data);
    },
    onSave(){
      this.$refs.holidayTable.onSave();
    },
    onRemove(){
      this.$refs.holidayTable.onRemove();
    },
    reSearch(){
      this.$emit("reSearch");
    }
  }
};
</script>

<style scoped>

</style>
