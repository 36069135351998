<template>
  <div>
    <zone-detail-btn
      :zoneIdx="zoneIdx"
      @search="onSearch"
      @update="onModify"
      @close="onClose"
    />
    <!-- @remove="onRemove"
      @reset="onReset" -->
    <div class="row-vh d-flex flex-row">
      <div class="w-100">
        <div class="d-flex flex-row align-items-center mt-3">
          <div class="col-4 text-left d-flex">
            <span class="h5 mb-0">{{$t('구역명')}}</span>
          </div>
          <div class="col-8">
            <valid-input
            :inputType="'text'"
            :inputValue.sync="model.zoneName"
            :rules="rules.ZONE_NAME_RULE"
            :errorMessage="validMessage.COMMON_BOTH_LENGTH_VALID_MESSAGE(1, 30)"
            ></valid-input>
            <!-- <input :ref="'zoneName'" class="form-control" v-model="model.zoneName" @keyup="onCheckedUpperZoneIdx" /> -->
          </div>
        </div>
      </div>
      <div class="w-100">
        <div class="d-flex flex-row align-items-center mt-3">
          <div class="col-4 text-left d-flex">
            <span class="h5 mb-0">{{$t('구역구분')}}</span>
          </div>
          <div class="col-8">
            <select class="form-control" :ref="'zoneDvsnCode'"
             v-model="model.zoneDvsnCode" @change="onCheckedUpperZoneIdx">
              <option v-for="(code, index) in codes"
                :key="index"
                :value="code.smallCode">
              {{ code.smallCodeName }} 
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="w-100">
        <div class="d-flex flex-row align-items-center mt-3">
          <div class="col-4 text-left d-flex">
            <span class="h5 mb-0">{{$t('상위 구역')}}</span>
          </div>
          <div class="col-8">
            <!-- <input :ref="'upperZoneIdx'" class="form-control" v-model="model.upperZoneIdx" @keyup="onCheckedUpperZoneIdx"/> -->
            <tree-select
              v-model="model.upperZoneIdx" 
              :ref="'upperZoneIdx'"
              :load-options="loadOptions"
              :placeholder="controlMessages.ZONE_TREE_PLACEHOLDER_MESSAGE"
              :noChildrenText="controlMessages.ZONE_TREE_NOCHILDREN_MESSAGE"
              :noResultsText="$t(controlMessages.COMMON_TREE_NOSEARCH_MESSAGE)"
              :multiple="false" :options="options"
              :append-to-body='true'
           />
          </div>
        </div>
      </div>
      <div class="w-100">
        <div class="d-flex flex-row align-items-center mt-3">
          <div class="col-4 text-left d-flex">
            <span class="h5 mb-0">{{$t('구역 상세')}}</span>
          </div>
          <div class="col-8">
            <valid-input
            :inputType="'text'"
            :inputValue.sync="model.zoneDetailDsc"
            :rules="rules.ZONE_DETAIL_RULE"
            :NonStatus="true"
            ></valid-input>

            <!-- <input :ref="'zoneDetailDsc'" class="form-control" v-model="model.zoneDetailDsc"/> -->
          </div>
        </div>
      </div>
      <div class="w-100">
        <div class="d-flex flex-row align-items-center mt-3">
          <div class="col-4 text-left d-flex">
            <span class="h5 mb-0">{{$t('사용여부')}}</span>
          </div>
          <div class="col-8">
            <select class="form-control" :ref="'enabled'" v-model="model.enabled" @change="onCheckedUpperZoneIdx">
              <option value="Y">{{$t('사용')}}</option>
              <option value="N">{{$t('사용안함')}}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="row-vh d-flex flex-row">
      <div class="w-100">
        <div class="d-flex flex-row align-items-center mt-3">
          <div class="col-4 text-left d-flex">
            <span class="h5 mb-0">도면파일</span>
          </div>
          <div class="col-8 ">
            <input type="file" id="file" ref="file" v-on:change="handleFileUpload()"/>
            <img :src="dsnFile"/>
          </div>
        </div>
      </div>
      <div class="w-100">
        <div class="d-flex flex-row align-items-center mt-3">
          <div class="col-4 text-left d-flex">
            <span class="h5 mb-0"></span>
          </div>
          <div class="col-8">
            
          </div>
        </div>
      </div>
      <div class="w-100">
        <div class="d-flex flex-row align-items-center mt-3">
          <div class="col-4 text-left d-flex">
            <span class="h5 mb-0"></span>
          </div>
          <div class="col-8">
           
          </div>
        </div>
      </div>
      <div class="w-100">
        <div class="d-flex flex-row align-items-center mt-3">
          <div class="col-4 text-left d-flex">
            <span class="h5 mb-0"></span>
          </div>
          <div class="col-8">
            
          </div>
        </div>
      </div>
      <div class="w-100">
        <div class="d-flex flex-row align-items-center mt-3">
          <div class="col-4 text-left d-flex">
            <span class="h5 mb-0"></span>
          </div>
          <div class="col-8">
            
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import backEndApi from "../../../../api/backEndApi";
import zoneDetailBtn from "./ZoneDetailBtn.vue";
import * as rules from "@src/consts/ruleConsts.js";
import * as validMessage from "@src/consts/validMessageConsts.js";
import * as popupMessages from "@src/consts/popupMessageConsts";
import * as controlMessages from "@src/consts/controlMessageConsts";
import AppErrorHandler from "@src/appErrorHandler";

export default {
  components: {
    zoneDetailBtn
  },
  props: ["zoneIdx", "isRegist"],
  watch: {
    data() {
      if (!this.zoneIdx) {
        // this.model.enabled= null;
        // this.model.upperZoneIdx= null;
        // this.model.zoneDetailDsc= null;
        // this.model.zoneDvsnCode= null;
        // this.model.zoneIdx= null;
        // this.model.zoneName= null;

        this.model.buildIdx = null;
        this.model.dsnFileName = null;
        this.model.dsnFilePath = null;
        this.model.enabled = null;
        this.model.upperZoneIdx = null;
        this.model.zoneDepth = null;
        this.model.zoneDetailDsc = null;
        this.model.zoneDvsnCode = null;
        this.model.zoneIdx = null;
        this.model.zoneName = null;
        this.model.zoneUseCode = null;
      }
    }
  },
  data() {
    return {
      model: {
        // enabled: null,
        // upperZoneIdx: null,
        // zoneDetailDsc: null,
        // zoneDvsnCode: null,
        // zoneIdx: null,
        // zoneName: null

        buildIdx: null,
        dsnFileName: null,
        dsnFilePath: null,
        enabled: null,
        upperZoneIdx: null,
        zoneDepth: null,
        zoneDetailDsc: null,
        zoneDvsnCode: null,
        zoneIdx: null,
        zoneName: null,
        zoneUseCode: null,
      },
      codes: null,

      zoneDvsnCodeList: null,
      options: null,

      dsnFile: null,
      rules,
      validMessage,
      popupMessages,
      controlMessages
    };
  },
  computed: {

  },
  async created() {
    console.log("ZoneInfoDetail");
    await this.getZoneInfo(this.zoneIdx);
    await this.getSmallCodeList();
    await this.getZoneListHierarchi();
    // this.getZoneDsnFile(this.zoneIdx);

  },
  methods: {
    async getSmallCodeList() {


      // 이전 리비전과 소스 에서 아래와 같이 변경함.
      /*           
        ↓↓↓↓↓
      */
      try {
        const result = await backEndApi.publicCode.getSmallCodeList("zoneDvsnCode");
        const data = result.data;

        console.log(data);
        this.codes = data;
      } catch (err) {
        new AppErrorHandler(err)
          .printErrorLog()
          .errHandling();
      }
    },
    async getZoneInfo(zoneIdx) {

      // 이전 리비전과 소스 에서 아래와 같이 변경함.
      /*           
        ↓↓↓↓↓
      */
      try {
        const result = await backEndApi.zoneInfo.getZoneInfo(zoneIdx);
        const data = result.data;

        //  this.model.enabled= data.enabled;
        //  this.model.upperZoneIdx= data.upperZoneIdx;
        //  this.model.zoneDetailDsc= data.zoneDetailDsc;
        //  this.model.zoneDvsnCode= data.zoneDvsnCode;
        //  this.model.zoneIdx= data.zoneIdx;
        //  this.model.zoneName= data.zoneName;

        this.model.buildIdx = data.buildIdx;
        this.model.dsnFileName = data.dsnFileName;
        this.model.dsnFilePath = data.dsnFilePath;
        this.model.enabled = data.enabled;
        this.model.upperZoneIdx = data.upperZoneIdx;
        this.model.zoneDepth = data.zoneDepth;
        this.model.zoneDetailDsc = data.zoneDetailDsc;
        this.model.zoneDvsnCode = data.zoneDvsnCode;
        this.model.zoneIdx = data.zoneIdx;
        this.model.zoneName = data.zoneName;
        this.model.zoneUseCode = data.zoneUseCode;

      } catch (err) {
        new AppErrorHandler(err)
          .printErrorLog()
          .errHandling();
      }
    },
    async getZoneListHierarchi() {

      // 이전 리비전과 소스 에서 아래와 같이 변경함.
      /*           
        ↓↓↓↓↓
      */
      try {
        this.options = [];

        const result = await backEndApi.zoneInfo.getZoneListHierarchi();
        const data = result.data;

        let temp = [];
        temp.push(data);
        this.options = this.setZoneTreeOption(temp);
        this.zoneInfo = data;
      } catch (err) {
        new AppErrorHandler(err)
          .printErrorLog()
          .errHandling();
      }
    },
    async getZoneDsnFile(zoneIdx) {

      // 이전 리비전과 소스 에서 아래와 같이 변경함.
      /*           
        ↓↓↓↓↓
      */
      try {
        const result = await backEndApi.zoneInfo.getZoneDsnFile(zoneIdx);
        let blob = new Blob([result.data], { type: result.headers['content-type'] });

        let image = URL.createObjectURL(blob);
        return image;

      } catch (err) {
        new AppErrorHandler(err)
          .printErrorLog()
          .errHandling();
      }
    },
    setZoneTreeOption(resultData) {

      resultData.forEach((data) => {

        data.id = data.zoneIdx;
        data.label = data.zoneName;

        if (data["children"]) {
          return this.setZoneTreeOption(data["children"]);
        } else {
          return data;
        }
      })

      return resultData;
    },
    onSearch() {
      // this.$emit("research");
      console.log("ZoneInfoDetail:onSearch");
    },
    onReset() {
      // this.$emit("reset");
      console.log("ZoneInfoDetail:onReset");

      if (this.isRegist) {
        // 신규 버튼을 눌렀으면
        // this.model.enabled= null;
        // this.model.zoneDetailDsc= null;
        // this.model.zoneDvsnCode= null;
        // this.model.zoneName= null;

        this.model.buildIdx = null;
        this.model.dsnFileName = null;
        this.model.dsnFilePath = null;
        this.model.enabled = null;
        this.model.upperZoneIdx = null;
        this.model.zoneDepth = null;
        this.model.zoneDetailDsc = null;
        this.model.zoneDvsnCode = null;
        this.model.zoneIdx = null;
        this.model.zoneName = null;
        this.model.zoneUseCode = null;
      } else {
        // this.model.enabled= null;
        // this.model.zoneDetailDsc= null;
        // this.model.zoneDvsnCode= null;
        // this.model.zoneName= null;

        this.model.buildIdx = null;
        this.model.dsnFileName = null;
        this.model.dsnFilePath = null;
        this.model.enabled = null;
        this.model.upperZoneIdx = null;
        this.model.zoneDepth = null;
        this.model.zoneDetailDsc = null;
        this.model.zoneDvsnCode = null;
        this.model.zoneIdx = null;
        this.model.zoneName = null;
        this.model.zoneUseCode = null;
      }
    },
    async onModify() {
      // this.$emit("reset");
      console.log("ZoneInfoDetail:onModify");

      if (this.isValidation()) {

        console.log(this.model.upperZoneIdx, this.model.zoneIdx);

        if(this.model.upperZoneIdx === this.model.zoneIdx){
          this.alertDanger(popupMessages.ZONE_SAME_UPPER_ZONE_POPUP_MESSAGE);
          return;
        }


        // 이전 리비전과 소스 에서 아래와 같이 변경함.
        /*           
          ↓↓↓↓↓
        */
        try {
          const result = await backEndApi.zoneInfo.updateZoneInfo(this.model);
          const data = result.data;

          console.log(data);
          this.alertNoti(popupMessages.COMMON_UPDATE_POPUP_MESSAGE);

          this.$emit("reSearch");

        } catch (err) {
          new AppErrorHandler(err)
            .printErrorLog()
            .errHandling();
        }
      }
    },
    // onRemove(){

    //   console.log("ZoneInfoDetail:onRemove");

    //   this.alertQuestion("삭제하시겠습니까?", this.model.zoneName).then((result) => {
    //     if (!result.value) return;

    //     backEndApi.zoneInfo.deleteZoneInfo(this.model.zoneIdx).then(({ data }) => {
    //         if(!data.errno){
    //           console.log(data);
    //           this.alertNoti("삭제 하였습니다.");

    //           this.$emit("reSearch");
    //         }else{
    //           this.alertNoti("삭제에 실패하였습니다.");
    //         }
    //     });
    //   });
    // },
    onClose() {
      this.$emit("close");
    },
    isValidation() {
      var flag = true;
      if (!this.model.zoneName) {
        this.alertNoti(popupMessages.ZONE_UPDATE_NAME_VALID_POPUP_MESSAGE);
        flag = false;
      } else if (!this.model.zoneDvsnCode) {
        this.alertNoti(popupMessages.ZONE_UPDATE_DIV_VALID_POPUP_MESSAGE);
        flag = false;
      } else if (!this.model.upperZoneIdx) {
        this.alertNoti(popupMessages.ZONE_UPDATE_UPPER_VALID_POPUP_MESSAGE);
        flag = false;
      } else if (!this.model.enabled) {
        this.alertNoti(popupMessages.COMMON_USAGE_VALID_POPUP_MESSAGE);
        flag = false;
      }

      return flag;
    },
    onCheckedUpperZoneIdx() {

      var flag = false;

      console.log(this.model.upperZoneIdx);

      if(this.model.upperZoneIdx === null){
        this.alertNoti(popupMessages.ZONE_UPDATE_UPPER_VALID_POPUP_MESSAGE);
        this.model.zoneDvsnCode = null;
        return;
      }

      if (!this.model.upperZoneIdx) {
        if (this.model.upperZoneIdx == 0) {
          flag = false;
        } else {
          this.alertNoti(popupMessages.ZONE_UPDATE_ZONE_VALID_POPUP_MESSAGE);
          this.model = {
            // enabled: null,
            // upperZoneIdx: null,
            // zoneDetailDsc: null,
            // zoneDvsnCode: null,
            // zoneIdx: null,
            // zoneName: null

            buildIdx: null,
            dsnFileName: null,
            dsnFilePath: null,
            enabled: null,
            upperZoneIdx: null,
            zoneDepth: null,
            zoneDetailDsc: null,
            zoneDvsnCode: null,
            zoneIdx: null,
            zoneName: null,
            zoneUseCode: null,
          }
          flag = true;
        }
      }

      return flag;
    },
    loadOptions({ callback }) {
      callback();
    },
    handleFileUpload() {
      this.dsnFile = this.$refs.file.files[0];
    }
  },
};
</script>
