<template>
	<b-table
		hover
		no-border-collapse
		show-empty
		sticky-header
		ref="selectableTable"
		:class="'text-center'"
		:items="items"
		:fields="fields"
		:empty-text="$t('조회된 결과가 없습니다.')"
		:select-mode="'single'"
		selectable
		:details-td-class="'rowDetailStyle'"
		@row-clicked="onRowDetail"
	>
		<template #row="rows">
			<div></div>
		</template>

		<template #empty="scope">
			<h4>{{ scope.emptyText }}</h4>
		</template>

		<template #cell(selected)="{ rowSelected }">
			<template v-if="rowSelected">
				<span>&check;</span>
				<span class="sr-only">Selected</span>
			</template>
			<template v-else>
				<span>&nbsp;</span>
				<span class="sr-only">Not selected</span>
			</template>
		</template>

		<template #cell(index)="data">
			{{ data.index + 1 }}
		</template>

		<template #table-busy>
			<div class="text-center my-2">
				<b-spinner class="align-middle"> </b-spinner>
				<strong>로딩 중... </strong>
			</div>
		</template>

		<template #row-details="row">
			<b-card :style="{ width: '90%', margin: 'auto' }">
				<detail-btn @new="onNew" />
				<detail-card-grid :ref="'detailGrid'" :data="detailItems" @modify:item="onModify" />
			</b-card>
		</template>
	</b-table>
</template>

<style scoped>
	.b-table-sticky-header {
		max-height: 760px;
	}
</style>

<script>
	import backEndApi from "../../../../../api/backEndApi";
	import detailCardGrid from "../../../../management/clbrtn/common/ClbrtnHistDetail.vue";
	import detailBtn from "../../../../management/clbrtn/common/ClbrtnHistDetailBtn.vue";

	export default {
		components: { detailCardGrid, detailBtn },
		props: ["data"],
		data() {
			return {
				fields: null,
				items: null,
				emptyText: null,
				selectedRow: [],

				detailItems: [],
				param: {
					serverIdx: null,
					channelIdx: null,
				},
			};
		},
		watch: {
			data() {
				this.selectedRow._showDetails = !this.selectedRow._showDetails;
				this.setItems();
			},
		},
		created() {
			this.setFields();
			this.setItems();
		},
		mounted() {},
		methods: {
			// onRowSelected(item) {
			//   if (!item.length > 0) {
			//     this.selectedRow._showDetails = false;
			//     this.selectedRow = [];
			//   }
			// },
			async onRowDetail(row) {
				this.param = {
					facilityIdx: row.facilityIdx,
				};

				let result = await backEndApi.clbrtnHist.getClbrnHistList(this.param);
				this.detailItems = result.data;
				// this.param = {
				//   serverIdx: row.serverIdx,
				//   channelIdx: row.channelIdx,
				// };

				if (this.selectedRow !== null) {
					if (this.selectedRow.facilityIdx === row.facilityIdx) {
						this.selectedRow._showDetails = !this.selectedRow._showDetails;
						this.selectedRow = null;
						return;
					}

					this.selectedRow._showDetails = !this.selectedRow._showDetails;
				}
				// 이전 선택된 row close
				// this.selectedRow._showDetails = !this.selectedRow._showDetails;

				// // 선택된 row open
				row._showDetails = !row._showDetails;

				// 선택된 row 저장
				this.selectedRow = row;

				console.log(this.selectedRow);
			},
			clearDetailGridSeclected() {
				this.$refs.detailGrid.clearSeclected();
			},
			clearSeclected() {
				this.$refs.selectableTable.clearSelected();
			},
			setFields() {
				this.fields = [
					{ key: "index", label: global.xe.$t("순번") },
					{ key: "facilityName", label: global.xe.$t("계측기") },
					{ key: "meterTypeName", label: global.xe.$t("계측기 종류") },
					{ key: "clbrPeriodName", label: global.xe.$t("검교정 주기") },
					{ key: "instDate", label: global.xe.$t("설치일자") },
					{ key: "lastClbrnDate", label: global.xe.$t("최종 검교정 일자") },
					{ key: "nextClbrnDate", label: global.xe.$t("다음 검교정 일자") },
					{ key: "remainDays", label: global.xe.$t("검교정 남은 일수") },
					{ key: "enableName", label: global.xe.$t("계측기 운영여부") },
				];
			},
			setItems() {
				if (this.data) {
					this.items = this.data.map((data) => {
						let keys = Object.keys(data);

						keys.forEach((key) => {
							if (key === "enableName") {
								data[key] = global.xe.$t(data[key]);
							}
						});
						return { ...data, _showDetails: false };
					});
				}
			},
			onNew() {
				this.$emit("new:param", this.param);
			},
			onModify(item) {
				this.$emit("modify:param", { param: this.param, item: item });
			},
			onRemove() {
				this.$refs.detail.onRemove();
			},
			pointLogModal(ptAddr) {
				this.$emit("pointLog:ptAddr", ptAddr);
			},
			async reSearch(param) {
				// 이전 리비전과 소스 에서 아래와 같이 변경함.
				/*           
				↓↓↓↓↓
			*/
				try {
					const result = await backEndApi.clbrtnHist.getClbrnHistList(param);
					this.detailItems = result.data;
				} catch {
					this.detailItems = null;
				}
			},
		},
	};
</script>
