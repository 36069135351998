<template>  
  <div class="mt-4 text-center">
    <button type="button" class="ml-3 btn btn-light" @click="onSave()" :disabled="disabled">저장</button>
  </div>
</template>

<script>

export default {
  components: {},
  props: ['disabled'],
  watch: {},
  data() {
    return {
    };
  },
  computed: {
    
  },
  created() {
  },
  methods: {
    // 저장 버튼 
    onSave() {
      this.$emit("insert");
    }
  },
};
</script>
