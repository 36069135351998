<template>
  <!-- 구역관리 -->
  <div class="row-vh d-flex flex-row justify-content-end mb-3 align-items-center">
    <div class="align-content-center font-weight-bold p-5 ml-3" style="font-size: 15px;">
      <span>{{$t('사용여부')}}</span>
    </div>
    <combo-box :ref="'combo'" :type="type" @search:enabled="onSearch" />
    <button-box :type="type"/>
  </div>
</template>

<script>
import ComboBox from "../../component/comboBox/management/ZoneInfoCombo.vue";

export default {
  components: {
    ComboBox,
  },
  props: ["codes","type"],
  watch: {},
  data() {
    return {
      param : null,
      largeCode : null
    };
  },
  computed: {
    
  },
  created() {
    
  },
  mounted() {
    
  },
  methods: {
    onSearch(data){
      if(this.type == "zone"){
        this.$emit("search:enabled",data);
      }else if(this.type == "code"){
        this.$emit("search:code",data);
      }
    },

    setEnabled(enabled){
      this.$refs.combo.setEnabled(enabled);
    },
    setCodes(code){
      this.$refs.combo.setCodes(code);
    },
    getEnabledValue(){
      return this.$refs.combo.getEnabledValue();
    }
  },
};
</script>
