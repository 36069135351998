<template>
  <div class="row">
    <b-table hover no-border-collapse show-empty bordered sticky-header="762px" head-variant="dark" table-variant="light"
      ref="selectableTable" :style="'min-width:100%; z-index:0; col-6'" :class="'text-center'" :items="items"
      :fields="fields" :empty-text="$t('조회된 결과가 없습니다.')" :select-mode="'single'" :details-td-class="'rowDetailStyle'">
      <!--  @row-selected="onRowSelected"
     @row-clicked="onRowDetail" -->
      <b-tbody>
        <b-td></b-td>
      </b-tbody>

      <template #cell(zoneName)="data" class="text-left">
        {{ data.item.zoneName }}
      </template>

      <template #empty="scope">
        <h4>{{ scope.emptyText }}</h4>
      </template>

      <template #cell(selected)="{ rowSelected }">
        <template v-if="rowSelected">
          <span>&check;</span>
          <span class="sr-only">Selected</span>
        </template>
        <template v-else>
          <span>&nbsp;</span>
          <span class="sr-only">Not selected</span>
        </template>
      </template>

      <template #cell(index)="data">
        {{ data.index + 1 }}
      </template>

      <template #cell(btn)="row">
        <b-button variant="outline-info" size="sm" class="mr-2" @click="onRowDetail(row)">
          <i class="fas fa-pen fa-xs"></i>
        </b-button>
        <b-button variant="outline-primary" size="sm" class="mr-2" @click="newModal(row.item.zoneIdx)">
          <i class="fas fa-plus fa-xs"></i>
        </b-button>
        <b-button v-if="row.item.zoneIdx != 1" variant="outline-danger" size="sm" :style="{ fontWeight: '900' }"
          @click="onRemove(row.item)">
          X
        </b-button>
      </template>

      <template #row-details :class="'bg-white'" >
        <b-card>
          <detail-box :ref="'detail'" :zoneIdx="zoneIdx" @reSearch="reSearch" @close="onClose" />
        </b-card>
      </template>
    </b-table>
  </div>
</template>

<style>


.table-head-wd100 {
  width: 100px !important;
}

.table-head-wd150 {
  width: 150px !important;
}

.table-head-wd200 {
  width: 200px !important;
}

.table-head-wd250 {
  width: 250px !important;
}

.table-head-wd300 {
  width: 300px !important;
}

.table-head-wd350 {
  width: 350px !important;
}

.table-head-wd400 {
  width: 400px !important;
}

.table-head-wd550 {
  width: 550px !important;
}

.table-head-wd600 {
  width: 600px !important;
}

.table-data-pd {
  padding: 15px !important;
}

.table-indent-0 {
  text-indent: 0em;
}

.table-indent-1 {
  text-indent: 1em;
}

.table-indent-2 {
  text-indent: 2em;
}

.table-indent-3 {
  text-indent: 3em;
}

.table-indent-4 {
  text-indent: 4em;
}

.table-indent-5 {
  text-indent: 5em;
}

.table-indent-6 {
  text-indent: 6em;
}

.table-indent-7 {
  text-indent: 7em;
}

.table-indent-8 {
  text-indent: 8em;
}

.table-indent-9 {
  text-indent: 9em;
}

.table-indent-10 {
  text-indent: 10em;
}

.table-indent-11 {
  text-indent: 11em;
}

.table-indent-12 {
  text-indent: 12em;
}

.table-indent-13 {
  text-indent: 13em;
}

.table-indent-14 {
  text-indent: 14em;
}

.table-indent-15 {
  text-indent: 15em;
}

.table-indent-16 {
  text-indent: 16em;
}

.table-indent-17 {
  text-indent: 17em;
}

.table-indent-18 {
  text-indent: 18em;
}

.table-indent-19 {
  text-indent: 19em;
}

.table-indent-20 {
  text-indent: 20em;
}

.table-indent-21 {
  text-indent: 11em;
}

.table-indent-22 {
  text-indent: 12em;
}

.table-indent-23 {
  text-indent: 13em;
}

.table-indent-24 {
  text-indent: 14em;
}

.table-indent-25 {
  text-indent: 15em;
}

.table-indent-26 {
  text-indent: 16em;
}

.table-indent-27 {
  text-indent: 17em;
}

.table-indent-28 {
  text-indent: 18em;
}

.table-indent-29 {
  text-indent: 19em;
}
</style>

<script>
import backEndApi from "@api/backEndApi.js";
import detailBox from "@views/management/zone/detail/ZoneInfoDetail.vue";
import AppErrorHandler from "@src/appErrorHandler";
import * as popupMessages from "@src/consts/popupMessageConsts.js";

export default {
  components: {
    detailBox,
  },
  props: ["data"],
  data() {
    return {
      fields: null,
      items: null,
      emptyText: null,
      selected: [],
      selectedRow: [],

      zoneDvsnCodeList: null,
      zoneInfoDetail: [],

      depth: 0,
      tempDepth: null,
    };
  },
  watch: {
    data() {
      this.setItems();
    },
    // enabled(){
    //   console.log(this.enabled);
    // }
  },
  created() {
    console.log("Zone Grid");    
    this.getZoneDvsnCode();
  },
  mounted() {
    this.setFields();
    this.setItems();
  },
  methods: {
    onRowSelected(item) {
      if (!item.length > 0) {
        this.selectedRow._showDetails = false;
        this.selectedRow = [];
      } else {
        this.zoneIdx = item[0].zoneIdx;
        // backEndApi.zoneInfo.getZoneInfo(item[0].zoneIdx).then(({ data }) => {
        //   if(!data.errno){
        //     console.log(data);
        //     this.zoneInfoDetail = [];
        //     this.zoneInfoDetail.push(data);
        //   }
        // });
      }
    },
    // onRowDetail(row){
    //   this.zoneIdx = row.zoneIdx;

    //   // 이전 선택된 row close
    //   this.selectedRow._showDetails =  !this.selectedRow._showDetails;

    //   // // 선택된 row open
    //   row._showDetails = !row._showDetails;

    //   // 선택된 row 저장
    //   this.selectedRow = row;
    // },
    onRowDetail(row) {
      row.toggleDetails();

      this.selectedRow._showDetails = !this.selectedRow._showDetails;

      row.item._showDetails = !row._showDetails;

      this.selectedRow = row.item;

      this.zoneIdx = row.item.zoneIdx;
    },
    onClose() {
      this.selectedRow._showDetails = false;
      this.selectedRow = [];
    },
    clearSeclected() {
      this.$refs.selectableTable.clearSelected();
    },
    getZoneDvsnCode() {
      // 구역 구분코드 이름을 알기위하여 호출
      backEndApi.publicCode.getSmallCodeList("zoneDvsnCode").then(({ data }) => {
        console.log(data);
        this.zoneDvsnCodeList = data;
      });
    },
    setFields() {
      this.fields = [
        { key: "zoneName", label: global.xe.$t("구역명"), thClass: "table-head-wd300", tdClass: "text-left table-data-pd" },
        { key: "zoneDvsnName", label: global.xe.$t("구역 구분"), thClass: "table-head-wd250" },
        { key: "upperZoneIdxName", label: global.xe.$t("구역 상세") },
        { key: "enabled", label: global.xe.$t("사용여부"), thClass: "table-head-wd100" },
        { key: "btn", label: "", thClass: "table-head-wd150" },
      ];
    },
    setItems(enabled) {
      this.items = [];
      this.setDepth(this.data, 0);
      this.setZoneTreeOption(this.data, enabled);
      console.log(this.items);
    },
    setDepth(resultData, depth) {
      resultData.forEach((data) => {
        if (!data["depth"]) {
          data.depth = depth;
        }
      });

      resultData.forEach((data) => {
        if (data["children"]) {
          depth++;
          this.setDepth(data["children"], depth);
        }
      });
    },
    setZoneTreeOption(resultData, enabled) {
      // 기존 Tree형태의 데이터를 grid 형태로 데이터로 변환
      // Tree데이터 호출 시 zoneName > text로 컬럼 네이밍 변환 요청 했음
      // Tree api가 나온 후 화면 변경으로 인한 데이터 변환 작업 실시

      resultData.forEach((data) => {
        // let zoneDvsnCode = this.zoneDvsnCodeList.filter((code) => code.smallCode == data.zoneDvsnCode);

        if (data.zoneName == "test2") {
          console.log("!23");
        }

        if (!enabled || enabled == data.enabled) {
          let result = {
            zoneIdx: data.zoneIdx,
            upperZoneIdx: data.upperZoneIdx,
            zoneName: data.zoneName,
            zoneDvsn: data.zoneDvsn,
            enabled: data.enabled,
            _cellVariants: { zoneName: "indent-" + data.depth },
          };

          this.items.push(result);

          if (data["children"]) {
            this.setZoneTreeOption(data["children"], enabled);
          }
        }
      });
    },
    reSearch() {
      this.$emit("reSearch");
    },
    newModal(zoneIdx) {
      this.$emit("newModal:zoneIdx", zoneIdx);
    },
    async onRemove(item) {

      const result = await this.alertConfirmWarning(popupMessages.COMMON_DELETE_CONFIRM_POPUP_MESSAGE);

      if (!result.value)
        return;

      try {
        const result = await backEndApi.zoneInfo.deleteZoneInfo(item.zoneIdx);
        console.log(result.data);
        await this.alertNoti(popupMessages.COMMON_DELETE_POPUP_MESSAGE);

        this.reSearch();
      } catch (err) {
        new AppErrorHandler(err)
          .setOtherMessage(popupMessages.COMMON_REMOVE_ERROR_POPUP_MESSAGE, this.alertDanger)
          .errHandling();
      }
    },
  },
};
</script>
